import { Autocomplete, Button, DialogActions, DialogContent, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../../../../redux/actions/modals.actions';

const LocationSelectDialog = ({ locations, onLocationSelected }) => {
  const dispatch = useDispatch();
  const [selectedLocation, setSelectedLocation] = useState(locations[0]);

  const onClose = () => {
    dispatch(hideModal('LOCATION_SELECT_DIALOG'));
  };

  const handleSelect = () => {
    onLocationSelected(selectedLocation);
    onClose();
  };

  return (
    <>
      <DialogContent sx={{ padding: '1rem', width: '50vw' }}>
        <Autocomplete
          fullWidth
          options={locations}
          getOptionLabel={(option) => option.label}
          size="small"
          style={{ width: '100%' }}
          value={selectedLocation}
          onChange={(e, newValue) => setSelectedLocation(newValue)}
          renderInput={(params) => <TextField {...params} label="Location" required variant="outlined" />}
          ListboxProps={{
            style: {
              maxHeight: '150px',
            },
          }}
        />
      </DialogContent>
      <DialogActions sx={{ padding: '0.5rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSelect}>Select</Button>
      </DialogActions>
    </>
  );
};

export default LocationSelectDialog;

/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { fetchNationalAccountById } from '../../redux/actions/nationalAccount.actions';
import { NATIONAL_ACCOUNT_NOTE_LABELS, NATIONAL_ACCOUNT_NOTE_TYPE } from '../../utils/consts/nationalAccount.consts';
import { getHigherAccountNumber } from '../../utils/event.utils';
import SimpleText from '../Text/SimpleText';

const NationalAccountCourseDisplayHook = ({
  noteType,
  account: accountNumber,
  notes: accountNotes,
  customer,
  style,
}) => {
  const noteField = `${noteType === NATIONAL_ACCOUNT_NOTE_TYPE.COURSE ? 'courseConfirmation' : noteType?.toLowerCase()}Notes`;
  const [notes, setNotes] = useState(accountNotes);
  const hasNotes = notes && !_.isEmpty(notes);
  const label = NATIONAL_ACCOUNT_NOTE_LABELS[noteType];

  useEffect(() => {
    const getNotes = async () => {
      const accountId = getHigherAccountNumber(customer);
      if (accountId || accountNumber) {
        const account = await fetchNationalAccountById(accountId ?? accountNumber);
        setNotes(
          account && account[noteField] && !_.isEmpty(account[noteField].note) ? account[noteField].note : undefined,
        );
      }
    };
    if (!hasNotes) getNotes();
  }, [accountNotes]);

  const noteComponent = () => <SimpleText txt={notes ?? ''} style={style} />;

  return {
    hasNotes,
    label,
    noteComponent,
  };
};

export default NationalAccountCourseDisplayHook;

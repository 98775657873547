/* eslint-disable no-use-before-define */
import { Container, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import EventDialog from '../../components/Calendar/CalendarComponents/EventDialog/EventDialog';
import LocationSelectDialog from '../../components/Calendar/CalendarComponents/EventDialog/EventDialogControl/LocationSelectDialog';
import ActionsFooter from '../../components/CustomerDashboard/ActionsFooter';
import CustomerOrdersDashboardStep from '../../components/CustomerDashboard/CustomerOrdersDashboardStep';
import SearchCustomerDashboardStep from '../../components/CustomerDashboard/SearchCustomerDashboardStep';
import ContainerItem from '../../components/LayoutBuilders/ContainerItem';
import Spinner from '../../components/SpinnerOverlay/Spinner';
import SimpleText from '../../components/Text/SimpleText';
import { SET_CURRENT_CUSTOMER } from '../../redux/actions/actionTypes/customers';
import { fetchCommissionLevels } from '../../redux/actions/commissionLevels.actions';
import { createCustomerQuote, emailCustomerQuote } from '../../redux/actions/customers.actions';
import { fetchLocations } from '../../redux/actions/locations.actions';
import { fetchMaterials } from '../../redux/actions/materials.actions';
import { hideModal, showModal } from '../../redux/actions/modals.actions';
import { fetchUnreadNotifications } from '../../redux/actions/notifications.actions';
import { fetchSAPMaterials } from '../../redux/actions/sap.actions';
import { fetchTasksData } from '../../redux/actions/tasks.actions';
import { fetchCurrentUser, getRegionsLocations } from '../../redux/actions/users.actions';
import { currentUserAvailableLocationsSelector } from '../../redux/selectors/user.selectors';
import { timezoneSelector } from '../../redux/selectors/utils.selectors';
import Completer from '../../utils/Completer';
import { BOOKING_MODE, COLORS, EVENT_TYPE } from '../../utils/consts';
import { prepareOnSiteEditingForm } from '../../utils/event.utils';

const useCreateQuote = (onCreated) => {
  const [loadingEdits, setLoadingEdits] = useState(false);
  const [customer, setCustomer] = useState();
  const [location, setLocation] = useState();

  const dispatch = useDispatch();
  const tz = useSelector(timezoneSelector);

  useEffect(() => {
    if (customer && location) {
      createNewQuote(location);
    }
  }, [customer, location]);

  // eslint-disable-next-line no-unused-vars
  const onSubmitQuote = async (values, modalName) => {
    const { customerName } = values;
    const quoteData = await dispatch(createCustomerQuote(customer.sold_to, values));
    if (quoteData) {
      dispatch(hideModal(modalName));
      const finalQuoteData = {
        ...quoteData,
        customerName,
      };
      showEmailQuoteConfirmation(finalQuoteData);
      if (onCreated) onCreated(finalQuoteData);
    }
  };

  const sendEmailToCustomer = async (quoteData) => {
    const customerID = customer.sold_to;
    const success = await dispatch(
      emailCustomerQuote({
        email: quoteData?.contact?.email ?? '',
        customer: customerID,
        customerName: quoteData.customerName,
        quote: quoteData.quoteID,
        createdByEmail: quoteData.createdByEmail,
        createdByName: quoteData.createdBy,
      }),
    );
    showToast(success);
  };

  const showToast = (success, msg) => {
    dispatch(
      showModal('COMPLETE_ORDER_STATUS', {
        modalType: success ? 'SUCCESS_ALERT' : 'ERROR_ALERT',
        modalProps: {
          message: msg ?? (success ? 'Email sent successfully' : 'Email failed to send'),
        },
      }),
    );
  };

  const showEmailQuoteConfirmation = (quoteData) => {
    const modalName = 'EMAIL_QUOTE_CONFIRM_DIALOG';
    dispatch(
      showModal(modalName, {
        modalType: 'FAS_CONFIRM_ALERT',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: false,
          confirmText: 'SEND',
          cancelText: "DON'T SEND",
          maxWidth: 'sm',
          title: 'EMAIL QUOTE',
          content: (
            <div style={{ padding: 22 }}>
              <Typography>Would you like to email this quote?</Typography>
              {container({
                spacing: 1,
                style: { justifyContent: 'flex-start' },
                children: [
                  item({
                    flex: 'auto',
                    children: [<Typography>Recepient:</Typography>],
                  }),
                  item({
                    flex: 'auto',
                    children: [
                      <Typography style={{ fontWeight: 'bold' }}>{`${quoteData.contact?.email ?? ''}`}</Typography>,
                    ],
                  }),
                ],
              })}
            </div>
          ),
          onConfirm: () => {
            sendEmailToCustomer(quoteData);
            dispatch(hideModal(modalName));
          },
        },
      }),
    );
  };

  const showQuoteCreationDialog = (selectedLocation) => {
    const modalName = 'EDIT_QUOTE_DIALOG';
    dispatch(
      showModal(modalName, {
        modalType: 'FAS_EVENT_DIALOG',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: true,
          confirmText: 'confirm',
          deleteText: 'delete',
          disableBackdropClick: true,
          maxWidth: 'md',
          title: 'NEW QUOTE',
          nestedScrolling: true,
          content: (
            <EventDialog
              modalName={modalName}
              onSubmit={(values) => onSubmitQuote(values, modalName)}
              updatedEvent={{ desc: { eventType: EVENT_TYPE.QUOTE } }}
              mode={BOOKING_MODE.quote}
              generic
              selectedLocation={selectedLocation}
            />
          ),
        },
      }),
    );
    setLocation();
  };

  const createNewQuote = async (selectedLocation) => {
    if (!customer) return;
    setLoadingEdits(true);
    try {
      const id = `Q${`${Date.now().valueOf()}`.padEnd(9, '0').substring(0, 10)}`;
      await prepareOnSiteEditingForm({
        dispatch,
        tz,
        formName: 'AddEventDialog',
        noDefaultDateTime: true,
        appendEmptyEvent: true,
        initialData: { desc: { eventType: EVENT_TYPE.QUOTE, quoteID: id } },
        preloaded: {
          customerObj: customer,
          customer: customer.sold_to,
          eventType: EVENT_TYPE.QUOTE,
        },
      });
      showQuoteCreationDialog(selectedLocation);
    } finally {
      setLoadingEdits(false);
    }
  };

  const container = ({ children, ...rst }) => <Container {...(rst ?? {})}>{children}</Container>;
  const item = ({ children, ...rst }) => <ContainerItem {...(rst ?? {})}>{children}</ContainerItem>;

  return {
    loadingEdits,
    setCustomer,
    setLocation,
  };
};

const CustomersDashboardView = ({ locations, dispatch }) => {
  const createQuoteBtnRef = React.useRef();
  const [selectedCustomer, setSelectedCustomer] = React.useState();
  const [showActionsBar, setShowActionsBar] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [regions, setRegions] = React.useState({});
  const { loadingEdits, setCustomer, setLocation } = useCreateQuote();

  const loadCintasRegions = async () => {
    try {
      setLoading(true);
      const fetchedRegions = await dispatch(getRegionsLocations(true));
      setRegions(fetchedRegions);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const load = async () => {
      dispatch(fetchMaterials());
      dispatch(fetchSAPMaterials());
      dispatch(fetchCommissionLevels());
      dispatch(fetchUnreadNotifications(1));
      dispatch(fetchTasksData());
      if (!locations.length) {
        await dispatch(fetchCurrentUser());
        await dispatch(fetchLocations());
      }
      loadCintasRegions();
    };

    load();
    return () => {};
  }, []);

  const onCustomerSelected = (customer, forceSelection = false) => {
    if (!customer) {
      setSelectedCustomer();
      prevStep();
    } else {
      setSelectedCustomer(customer);
      dispatch({
        type: SET_CURRENT_CUSTOMER,
        customer,
      });
      if (forceSelection) {
        nextStep();
      } else {
        setShowActionsBar(true);
      }
    }
  };

  const showSelectLocationModal = async () => {
    const completer = new Completer();
    const modalName = 'LOCATION_SELECT_DIALOG';
    dispatch(
      showModal(modalName, {
        modalType: 'FAS_EVENT_DIALOG',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: true,
          disableBackdropClick: false,
          maxWidth: 'lg',
          fullWidth: false,
          title: 'SELECT LOCATION',
          content: <LocationSelectDialog locations={locations} onLocationSelected={completer.complete} />,
        },
      }),
    );

    return completer.promise;
  };

  const onGenericQuote = useCallback(
    async (customer) => {
      setCustomer(customer);

      let location = locations[0];
      if (locations.length > 1) {
        location = await showSelectLocationModal();
      }

      setLocation(location);
    },
    [locations],
  );

  const STEPS = [
    new DashboardStep('Search Customer', (args) => <SearchCustomerDashboardStep {...args} />, {
      onCustomerSelected,
      regions,
      onGenericQuote,
      genericQuoteLoading: loadingEdits,
    }),
    new DashboardStep('Customer Orders', (args) => <CustomerOrdersDashboardStep {...args} />, {
      regions,
      customer: selectedCustomer,
      createQuoteBtnRef,
      onPrevStep: () => {
        prevStep();
        setSelectedCustomer();
      },
    }),
  ];
  const [currentStep, setCurrentStep] = React.useState(0);

  const nextStep = () => {
    if (currentStep === STEPS.length - 1) return;
    setShowActionsBar(false);
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    if (currentStep === 0) return;
    setShowActionsBar(false);
    setCurrentStep(currentStep - 1);
  };

  const header = () => (
    <SimpleText
      txt="Customer Dashboard"
      fontSize={30}
      style={{ fontWeight: 'bold', color: COLORS.CINTAS_BLUE, paddingTop: 10 }}
    />
  );

  const stepsView = () => {
    const { label, widget, args } = STEPS[currentStep];
    return loading ? spinner() : widget({ label, ...args });
  };

  const spinner = () => <Spinner spinnerStyle={{ color: COLORS.CINTAS_BLUE, marginTop: 20 }} />;

  const bottomActionsBar = () => (
    <ActionsFooter
      actionTitle="SELECT CUSTOMER"
      onClick={() => {
        if (selectedCustomer) nextStep();
      }}
    />
  );

  return (
    <>
      {header()}
      {stepsView()}
      {showActionsBar && selectedCustomer ? bottomActionsBar() : <></>}
    </>
  );
};

const mapStateToProps = (state) => {
  const locs = [
    ...Object.values(state.users?.data?.reportingTcis?.reduce((a, b) => ({ ...a, ...b.locations }), {}) ?? {}),
    ...(state.users?.data?.currentUser?.locs ?? []),
  ];
  const locationsIds = [...(currentUserAvailableLocationsSelector(state) || [])];
  const locations = locationsIds
    .map((locId) => {
      const loc = locs.find((l) => l.id === locId || locId === l.loc_id);
      return {
        id: loc?.id || loc?.loc_id,
        label: loc?.label || loc?.location_desc,
      };
    })
    .filter((l) => Boolean(l?.id) && Boolean(l?.label))
    .sort((locA, locB) => {
      if (locA.id < locB.id) return -1;
      if (locA.id > locB.id) return 1;
      return 0;
    });

  return { locations };
};

export default connect(mapStateToProps)(CustomersDashboardView);

export class DashboardStep {
  constructor(label, widget, args) {
    this.label = label;
    this.widget = widget;
    this.args = args ?? {};
  }
}

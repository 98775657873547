/* eslint-disable no-prototype-builtins */
/* eslint-disable no-empty */
/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import MuiDialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { change, Field, getFormValues, reduxForm } from 'redux-form';
import * as EventActions from '../../../redux/actions/events.actions';
import { showModal } from '../../../redux/actions/modals.actions';
import { locationCalendarSelection } from '../../../redux/selectors/calendar.selectors';
import { subcontractorForLoc } from '../../../redux/selectors/instuctors.selectors';
import { materialsDataSelector } from '../../../redux/selectors/materials.selector';
import {
  currentUserLocationsSelector,
  hasUserAccessSelector,
  reportingTcisAllLocations,
  userInfoSelector,
} from '../../../redux/selectors/user.selectors';
import { defaultView, ROLE_ACCESSES } from '../../../utils/consts';
import { calculateCalendarDatesRange, groupSubcontractorEvents } from '../../../utils/event.utils';
import MDAutoComplete from '../../Forms/FormComponents/MDAutoComplete/MDAutoCompleteField';
import Container from '../../LayoutBuilders/Container';
import ContainerItem from '../../LayoutBuilders/ContainerItem';
import LoadingDataSpinner from '../../SpinnerOverlay/LoadingDataSpinner';
import CalendarTable from '../CalendarTable';

export const formName = 'LocationsCalendar';

const fields = {
  location: 'location',
  events: 'events',
  customers: 'customers',
  viewport: 'viewport',
};

const LocationsCalendarDialogContent = withStyles((theme) => ({
  root: {
    minHeight: 850,
    maxHeight: 850,
    textAlign: 'center',
    overflow: 'hidden',
    [theme.breakpoints.down('xl')]: {},
  },
}))(MuiDialogContent);

const LocationsCalendarDialog = ({
  tcis,
  events,
  locations,
  selectedLocation,
  dispatch,
  materialsInfo,
  customers,
  user,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [eventsPromise, setEventsPromise] = React.useState(undefined);
  const hasOwnCalendar = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.ownCalendar]));

  React.useEffect(() => {
    if (selectedLocation) {
      onLocationChange();
    }
  }, [selectedLocation]);

  const abortPreviousEventsRequest = async () => {
    try {
      await eventsPromise?.abort();
    } catch (e) {}
  };

  const onLocationChange = async () => {
    if (!selectedLocation) return;
    try {
      setLoading(true);
      await abortPreviousEventsRequest();
      const promise = dispatch(EventActions.fetchLocationEvents({ locationTcis: [...tcis] }));
      setEventsPromise(promise);
      const loadedEvents = await promise;
      if (loadedEvents) {
        const subEvents = _.filter(loadedEvents, (ev) => ev.subcontracted);
        const regularEvents = _.filter(loadedEvents, (ev) => !ev.subcontracted);
        const grouppedSubEvents = groupSubcontractorEvents(subEvents);
        const eventsList = [...regularEvents, ...grouppedSubEvents];
        dispatch(change(formName, fields.events, [...eventsList]));
      }
      showToast(Boolean(loadedEvents));
    } catch (e) {
      // TODO
    } finally {
      setLoading(false);
    }
  };

  const showToast = (success) => {
    dispatch(
      showModal('LOCATION_EVENTS_STATUS', {
        modalType: success ? 'SUCCESS_ALERT' : 'ERROR_ALERT',
        modalProps: {
          message: success
            ? 'Location events ready!'
            : 'Something went wrong retrieving events for this location. Please, try again!',
        },
      }),
    );
  };

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;

  const locationSelect = () => (
    <Field
      label="Location"
      options={locations}
      variant="outlined"
      getLabel={(option) => option.label}
      getValue={(option) => option.id}
      id={fields.location}
      name={fields.location}
      component={MDAutoComplete}
      inputProps={{
        placeholder: 'Location',
      }}
      size="small"
      noBorderRadius
    />
  );

  const header = () =>
    container({
      spacing: 1,
      direction: 'column',
      style: { marginTop: 18 },
      children: [
        item({
          children: container({
            spacing: 1,
            style: { justifyContent: 'space-between' },
            children: [
              item({ flex: 4, children: locationSelect() }),
              item({ flex: 6, children: loading ? <LoadingDataSpinner /> : <></> }),
            ],
          }),
        }),
      ],
    });

  const calendar = () => (
    <CalendarTable
      hideOptionsBar
      disableEventCreation
      disableEditing={(order) => {
        if (!hasOwnCalendar) return false;
        return order?.instructor !== user.uid;
      }}
      forceInstructorDisplay
      currentTCI={user}
      loading={loading}
      events={events}
      materialsInfo={materialsInfo}
      onEventEdited={() => onLocationChange()}
      customers={customers}
      loadViewportEvents={async (date, view) => {
        await dispatch(change(formName, 'viewport', calculateCalendarDatesRange(date, view)));
        dispatch(change(formName, 'openCancelledEventsPopover', false));
        onLocationChange();
      }}
    />
  );

  return (
    <LocationsCalendarDialogContent>
      {container({
        spacing: 6,
        style: { overflow: 'scroll', padding: '0px 60px' },
        children: [item({ flex: 12, children: header() }), item({ flex: 12, children: calendar() })],
      })}
    </LocationsCalendarDialogContent>
  );
};

export default _.flow([
  connect((state) => {
    const tcis = [...(state.users?.data?.reportingTcis ?? [])];
    const user = userInfoSelector(state);
    const userRole = user?.role ?? '';
    const hasOwnCalendar = hasUserAccessSelector(state, [ROLE_ACCESSES.ownCalendar]);
    const locationsIds = hasOwnCalendar ? currentUserLocationsSelector(state) : reportingTcisAllLocations(state);
    const locations = locationsIds
      .map((locId) => {
        const match = tcis.find((t) => (t.locations ?? {}).hasOwnProperty(locId));
        return { id: locId, label: match?.locations?.[locId].location_desc ?? '' };
      })
      .filter((l) => Boolean(l?.id) && Boolean(l?.label))
      .sort((locA, locB) => {
        if (locA.id < locB.id) return -1;
        if (locA.id > locB.id) return 1;
        return 0;
      });

    const selectedLocation = locationCalendarSelection(state);
    const relevantTcis = selectedLocation
      ? tcis
          .filter((tci) => (tci.locations ?? {}).hasOwnProperty(selectedLocation?.id))
          .reduce((red, tci) => [...red, ...(!red.filter((t) => t.pernr === tci.pernr).length ? [tci] : [])], [])
      : tcis;

    if (hasOwnCalendar && !relevantTcis.find((tci) => tci.uid === user.uid)) {
      relevantTcis.push(user);
    }

    relevantTcis.push(subcontractorForLoc(state, selectedLocation?.id));

    return {
      tcis: relevantTcis,
      locations,
      selectedLocation,
      userRole,
      user: userInfoSelector(state),
      events: (getFormValues(formName)(state) ?? {})[fields.events],
      materialsInfo: materialsDataSelector(state),
    };
  }),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    initialValues: {
      [fields.events]: [],
      [fields.viewport]: calculateCalendarDatesRange(Date.now(), defaultView),
    },
  }),
])(LocationsCalendarDialog);

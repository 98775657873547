/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import moment from 'moment/moment';
import React from 'react';
import { change, initialize } from 'redux-form';
// import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import BrowserCompabilityDialog from '../components/BrowserCompabilityDialog/BrowserCompabilityDialog';
import { getPricing } from '../components/Calendar/CalendarComponents/EventDialog/EventDialogControl/CourseSelect';
import PersonalCalendarDialog from '../components/Calendar/CalendarComponents/PersonalCalendarDialog/PersonalCalendarDialog';
import ConfirmationEmailPopup from '../components/Modals/OrderModals/ConfirmationEmailPopup';
import SimpleText from '../components/Text/SimpleText';
import { fetchContactsByCustomerId } from '../redux/actions/contacts.actions';
import { mapMaterials } from '../redux/actions/materials.actions';
import { hideModal, showModal } from '../redux/actions/modals.actions';
import { fetchOrderSummary, fetchOrders, sendConfirmation } from '../redux/actions/orders.actions';
import { fetchPricing } from '../redux/actions/pricing.actions';
import { fetchSAPListings } from '../redux/actions/sap.actions';
import { materialSelectorOfMultipleTypes } from '../redux/selectors/materials.selector';
import {
  AFTER_HOUR_FEE_MATERIAL,
  BELOW_MIN_PRICE_CL,
  CALENDAR_VIEWS,
  CANCELLATION_FEE_MATERIAL,
  // COLORS,
  DEFAULT_TRUCK_NUMBER,
  EVENT_TYPE,
  MATERIAL_TYPE,
  NO_MAKE_DAY_DISCOUNT_CHANNELS,
  SPECIAL_TRUCK_NUMS,
  TRAVEL_FEE_MATERIAL,
  TRUCK_NUM_VALIDATION_REGEX,
  USER_ROLES,
  USER_ROLES_SAP_MAPPING,
} from './consts';
import { formatDuration, getNewDate } from './dateUtils';

export const priceToLevel = (price, commissionLevels) => {
  if (price < Number(commissionLevels[commissionLevels.length - 1].min)) {
    return BELOW_MIN_PRICE_CL;
  }
  if (price >= Number(commissionLevels[0].min)) {
    return commissionLevels[0];
  }
  return _.find(commissionLevels, (level) => price >= Number(level.min) && price < Number(level.max));
};

export const loadAddons = (addonData, materials) =>
  addonData.reduce((tot, addon) => tot.concat(materials.find((add) => add.id === addon)), []);

export const buildCourseUniqueId = (course) =>
  course ? `${course.id}-${course.event.startTime}:${course.event.endTime}` : '';

export const addSkillCheckCourses = (courses) => {
  /*
   *@params courses: [Object] containing course and optionally skillcheck
   */
  const newCourses = [];
  let courseSkillCheck = {};
  let actualSkillCheckParticipants = -1;
  let skillcheckEndTime;
  _.forEach(courses, (obj) => {
    const updatedCourse = { ...obj };
    updatedCourse.courseEndTime = obj?.courseStartTime.clone().add(obj?.course?.duration, 'minutes');
    newCourses.push(updatedCourse);
    if (obj?.skillcheckFull || obj?.skillcheckParticipants > 0) {
      actualSkillCheckParticipants = obj.skillcheckFull ? obj?.participants : obj?.skillcheckParticipants;
      // Create object with matching fields for booking
      courseSkillCheck = _.pick(obj, [
        'course',
        'participants',
        'customerPrice',
        'currency',
        'courseStartTime',
        'courseEndTime',
      ]);
      // Reassign fields to skillcheck values
      courseSkillCheck.participants = actualSkillCheckParticipants;
      courseSkillCheck.course = obj?.skillcheck;
      courseSkillCheck.new = obj.new;
      courseSkillCheck.customerPrice = obj?.skillcheckPrice?.customerPrice;
      courseSkillCheck.courseStartTime = updatedCourse.courseEndTime.clone();
      skillcheckEndTime = courseSkillCheck.courseStartTime
        .clone()
        .add(obj?.skillcheck.duration * actualSkillCheckParticipants, 'minutes');
      courseSkillCheck.courseEndTime = skillcheckEndTime;
      newCourses.push(courseSkillCheck);
    }
  });
  return newCourses;
};

export const getCancelPrice = async (events, customer, dispatch, inclusionsListing, reschedule) => {
  /*
   *@params order: [Object], events field of an order description
   *
   *@desc: Calculates price to be charged if an order is cancelled or rescheduled. Accounts for # of days before cancelation
   * and returns either full or 50% price.
   */
  let totalPrice = 0;
  // get the cancellation costs listing

  if (!reschedule) {
    if (!(_.find(inclusionsListing, { Material: CANCELLATION_FEE_MATERIAL }) ?? {})?.inlcuded) {
      return totalPrice;
    }
  }

  const priceListings = await dispatch(fetchPricing(customer, CANCELLATION_FEE_MATERIAL));

  // check if specific cancellation customer price is available
  const customerPricing = _.find(priceListings, { Customer: customer.sold_to });
  const customerPrice = customerPricing ? getPricing(customerPricing, customer.distribution_channel) : undefined;
  if (customerPrice) return Number(customerPrice);
  _.forEach(events, (event) => {
    const eventPrice =
      event.courseObj?.type === MATERIAL_TYPE.COURSE
        ? event.price?.amount ?? 0
        : event.participants_count * (event.price?.amount ?? 0);
    totalPrice += eventPrice;
  });

  const orderStart = events ? moment.utc(events[0]?.startTime ?? '') : moment.utc();
  const cancellationTime = moment.utc();
  const eightDaysBeforeCourse = orderStart.clone().subtract(8, 'days');
  const fourteenDaysBeforeCourse = orderStart.clone().subtract(14, 'days');
  if (cancellationTime.isBefore(fourteenDaysBeforeCourse)) {
    return 0;
  }

  if (cancellationTime.isBetween(fourteenDaysBeforeCourse, eightDaysBeforeCourse)) {
    return totalPrice * 0.5;
  }

  if (cancellationTime.isAfter(eightDaysBeforeCourse)) {
    return totalPrice;
  }
  return totalPrice;
};

export const makeDayDiscountCalculator = (bookingData, numCourses, customer) => {
  if (!bookingData || _.isEmpty(bookingData)) return {};
  const customerPrice = Number(bookingData.customerPrice ?? 0);
  const marketPrice = Number(bookingData.marketPrice ?? 0);
  const isMakeDayDiscount =
    bookingData.course?.type === MATERIAL_TYPE.COURSE &&
    numCourses > 1 &&
    customerPrice === marketPrice &&
    customerPrice !== 0 &&
    !NO_MAKE_DAY_DISCOUNT_CHANNELS.includes(customer.distribution_channel);
  const discountPercent = Number(bookingData.course?.discount ?? 0);
  const materialDiscount = discountPercent === 0 ? 0 : (discountPercent * customerPrice) / 100;

  return {
    isMakeDayDiscount,
    makeDayDiscount: materialDiscount,
    makeDayPercentage: discountPercent,
    adjustedPrice: customerPrice - materialDiscount,
  };
};

export const makeDayPriceDiscountCalculator = (
  courseType,
  numCourses,
  customerPrice,
  marketPrice,
  discountPercent,
  customer,
) => {
  const materialDiscount = discountPercent === 0 ? 0 : (discountPercent * customerPrice) / 100;
  const isMakeDayDiscount =
    courseType === MATERIAL_TYPE.COURSE &&
    numCourses > 1 &&
    customerPrice === marketPrice &&
    customerPrice !== 0 &&
    !NO_MAKE_DAY_DISCOUNT_CHANNELS.includes(customer.distribution_channel);

  return {
    isMakeDayDiscount,
    makeDayDiscount: materialDiscount,
    makeDayPercentage: discountPercent,
    adjustedPrice: customerPrice - materialDiscount,
  };
};

export const roleSapMapper = (role) => {
  if (!USER_ROLES[role]) {
    const sapRoleMatch = Object.values(USER_ROLES).find((r) => USER_ROLES_SAP_MAPPING[r].sapRoles.includes(role));
    if (!sapRoleMatch) return role;
    return sapRoleMatch;
  }

  return role;
};

export const calculateEventTotalPrice = (event, addons, materialsInfo) => {
  let basePrice = event.price?.amount ?? 0.0;
  const courseMatID = event.course;
  if (!courseMatID) return 0.0;
  const courseMatSkillcheck = materialSelectorOfMultipleTypes({
    materialsInfo,
    types: [MATERIAL_TYPE.SKILL_CHECK],
    id: courseMatID,
  });
  if (!_.isEmpty(courseMatSkillcheck)) {
    basePrice *= event?.participants_count ?? 0;
  }

  const addonsPrice = (addons ?? [])
    .filter((add) => Boolean(add.qty) && Boolean(add.price?.amount))
    .reduce((prev, add) => prev + add.price.amount * add.qty, 0.0);
  return basePrice + addonsPrice;
};

export const calculateCalendarDatesRange = (date, view, monthBuffer) => {
  switch (view) {
    case CALENDAR_VIEWS.DAY:
      return { start: moment(date).startOf('day'), end: moment(date).endOf('day') };
    case CALENDAR_VIEWS.WEEK:
      return { start: moment(date).startOf('week'), end: moment(date).endOf('week') };
    case CALENDAR_VIEWS.MONTH:
      // additional buffer days math because you can see adjacent weeks on month view
      return {
        start: moment(date)
          .startOf('month')
          .subtract(monthBuffer ?? 7, 'days'),
        end: moment(date)
          .endOf('month')
          .add(monthBuffer ?? 7, 'days'),
      };
    default:
      return calculateCalendarDatesRange(Date.now(), CALENDAR_VIEWS.WEEK);
  }
};

export const getCustomerLocationDisplay = (customer) =>
  customer ? `${_.startCase((customer.city ?? '').toLowerCase())}, ${(customer.region ?? '').toUpperCase()}` : '';

export const resetDeliverySignature = (formName, dispatch) => {
  dispatch(change(formName, 'deliverySignature', null));
  dispatch(change(formName, 'printedSignature', null));
  dispatch(change(formName, 'otherName', null));
};

export const buildSubcontractorId = (locId) => `sub${locId}`.replace(' ', '_').toLowerCase();

export const isTruckNumInvalid = (num) =>
  num !== DEFAULT_TRUCK_NUMBER && !TRUCK_NUM_VALIDATION_REGEX.test(num) && !SPECIAL_TRUCK_NUMS.includes(num);

export const groupSubcontractorEvents = (events) => {
  if (!events || _.isEmpty(events)) return events ?? [];
  const map = {};
  const toPlace = [...events].sort((a, b) => {
    const lengthA = moment.utc(a.endTime).valueOf() - moment.utc(a.startTime).valueOf();
    const lengthB = moment.utc(b.endTime).valueOf() - moment.utc(b.startTime).valueOf();
    return lengthA > lengthB ? 1 : -1;
  });

  while (toPlace.length > 0) {
    const longest = toPlace.pop();
    const longestStart = moment.utc(longest.startTime);
    const longestEnd = moment.utc(longest.endTime);
    let frameEnd = longestEnd.clone();
    const startInFrame = [];
    toPlace.forEach((ev, i) => {
      const evStart = moment.utc(ev.startTime);
      const evEnd = moment.utc(ev.endTime);
      if (evStart.isBetween(longestStart, longestEnd, null, '[]') || evStart.isSame(longestStart)) {
        if (evEnd.isAfter(frameEnd)) {
          frameEnd = evEnd;
        }

        startInFrame.push(ev);
        toPlace.splice(i, 1);
      }
    });
    const frameLabel = `${longestStart.valueOf()}-${frameEnd.valueOf()}`;
    map[frameLabel] = [longest, ...startInFrame];
  }
  const blocks = Object.keys(map).map((frame) => {
    const frameInterval = `${frame}`.split('-');
    const startTime = moment.utc(parseInt(frameInterval[0], 10));
    const endTime = moment.utc(parseInt(frameInterval[1], 10));
    return {
      id: `${frame}`,
      title: 'Subcontracted',
      startTime: startTime.toISOString(),
      endTime: endTime.toISOString(),
      subcontracted: true,
      eventType: EVENT_TYPE.ON_SITE,
      data: map[frame],
    };
  });

  return [...blocks];
};

export const formatEventForEditingForm = (e, ord, duplicateEvent) => {
  const {
    courseObj: { skillcheckID },
  } = e;
  const skillchecks = (ord.events ?? []).filter(
    (ev) => ev.courseObj.type === MATERIAL_TYPE.SKILL_CHECK && ev.parentEventID === e.id,
  );
  const skillcheckObjs = skillcheckID ? skillchecks.filter((sk) => sk.courseObj.id === skillcheckID) : [];
  const skillcheckObj =
    skillcheckObjs.find((sk) => sk.parentEventID === e.id) ?? (skillcheckObjs.length ? skillcheckObjs[0] : undefined);
  const coursePrice = () => {
    if (duplicateEvent) return undefined;
    return e.price?.makeDayDiscount ? (e.price?.amount ?? 0) + e.price?.makeDayDiscount : e.price?.amount ?? 0;
  };

  const addons = (e.addOns ?? []).filter(
    (ad) => !duplicateEvent || ![AFTER_HOUR_FEE_MATERIAL, TRAVEL_FEE_MATERIAL].includes(ad?.sap_material_number),
  );

  const obj = {
    ...(e ?? {}),
    eventId: duplicateEvent ? undefined : e.id,
    order: duplicateEvent ? undefined : ord.id,
    course: e.courseObj ?? {},
    participants: e.participants_count ?? 0,
    price: coursePrice(),
    distribution_channel: duplicateEvent ? undefined : e.distribution_channel,
    addons,
    selectedAddons: _.isEmpty(addons)
      ? undefined
      : addons.reduce(
          (prev, add) => ({
            ...prev,
            [`${(e.courseObj?.addons?.required ?? []).includes(add.id) ? 'REQ' : 'ZOPT'}${add.id}`]: { ...add },
          }),
          {},
        ),
    additional_participants: e.additional_participants ?? [],
    notes: e.notes ?? '',
    courseStartTime: moment.utc(e.startTime),
    courseEndTime: moment.utc(e.endTime),
    skillcheck: skillcheckObj
      ? {
          eventId: skillcheckObj.id ?? undefined,
          parentEventID: skillcheckObj.parentEventID,
          ...(skillcheckObj.courseObj ?? {}),
        }
      : undefined,
    skillcheckFull: skillcheckObj ? skillcheckObj?.participants_count === e.participants_count : undefined,
    skillcheckParticipants: skillcheckObj ? skillcheckObj.participants_count : undefined,
    skillcheckPrice: skillcheckObj ? skillcheckObj?.price?.amount ?? 0 : undefined,
  };
  return obj;
};

export const prepareOnSiteEditingForm = async ({
  orderId,
  preloaded,
  initialData,
  formName,
  dispatch,
  tz,
  noDefaultDateTime,
  appendEmptyEvent,
  duplicateEvent,
  dontInitialize,
}) => {
  const ord = preloaded ?? (await dispatch(fetchOrderSummary(orderId)));
  const fetchedContacts = await dispatch(fetchContactsByCustomerId(ord.customer));
  await dispatch(
    fetchSAPListings(ord.customerObj.sold_to, ord.customerObj.sales_organization, ord.customerObj.distribution_channel),
  );
  const courses = (ord.events ?? [])
    .filter((e) => e.courseObj.type !== MATERIAL_TYPE.SKILL_CHECK)
    .map((e, i) => formatEventForEditingForm(e, ord, duplicateEvent, i));
  const secondaryTci = _.find(ord.events, (e) => Boolean(e.secondaryTci)); // = 'cAl4W4hAQgdhKykKW1SNHEsi0ax1';

  if (appendEmptyEvent) {
    courses.push({});
  }

  const date = () => {
    if (duplicateEvent) return undefined;
    return initialData?.start || !noDefaultDateTime
      ? moment.utc(`${initialData?.start}`).tz(tz).format('YYYY-MM-DD')
      : undefined;
  };

  const onSiteData = {
    ...initialData?.desc,
    id: duplicateEvent ? undefined : initialData?.id,
    date: date(),
    startTime:
      initialData?.courseStartTime || !noDefaultDateTime ? moment.utc(courses?.[0]?.courseStartTime) : undefined,
    endTime:
      initialData?.courseEndTime || !noDefaultDateTime
        ? moment.utc(courses?.[courses?.length - 1]?.courseEndTime)
        : undefined,
    customer: ord.customerObj,
    customerID: ord.customerObj?.sold_to,
    instructorID: ord.instructor,
    instructorObj: duplicateEvent ? ord.events?.[0].instructor : initialData?.instructorObj,
    secondaryTci: ord.secondaryTci ?? secondaryTci,
    customerContact: fetchedContacts.find((c) => c.SAPContactID === ord.contactID),
    temporaryContact: ord.temporaryContact ? { ...ord.temporaryContact } : undefined,
    notes: ord.notes,
    order: orderId,
    confirmationsentDate: duplicateEvent ? undefined : ord.confirmationsentDate,
    price: duplicateEvent ? undefined : initialData?.desc?.price,
    courses,
    events: ord.events,
    fees: { travelFee: ord.travelInfo, afterHoursFee: ord.afterHoursFee },
  };

  if (!dontInitialize) await dispatch(initialize(formName, onSiteData));

  return onSiteData;
};

export const showAddToCalendarPopup = (orderId, dispatch) => {
  const modalName = 'ADD_TO_CAL_POPUP';
  dispatch(
    showModal(modalName, {
      modalType: 'FAS_CONFIRM_ALERT',
      modalProps: {
        bodyTextStyle: { fontSize: 18 },
        hideCancel: true,
        disableBackdropClick: false,
        maxWidth: 'sm',
        title: 'ADD TO PERSONAL CALENDAR',
        content: <PersonalCalendarDialog dispatch={dispatch} modalName={modalName} orderId={orderId} />,
        confirmText: 'OK',
        onConfirm: () => {
          dispatch(hideModal(modalName));
        },
      },
    }),
  );
};

export const showBrowserCompabilityPopup = (dispatch) => {
  const modalName = 'BROWSER_COMPABILITY_POPUP';
  dispatch(
    showModal(modalName, {
      modalType: 'FAS_EVENT_DIALOG',
      modalProps: {
        bodyTextStyle: { fontSize: 18 },
        hideCancel: true,
        disableBackdropClick: false,
        maxWidth: 'sm',
        title: 'BROWSER COMPABILITY',
        content: <BrowserCompabilityDialog dispatch={dispatch} modalName={modalName} />,
      },
    }),
  );
};

export const calcMaxParticipants = (obj, hasSecondaryInstructor, def = 0) =>
  (obj?.participants?.max ?? def) * (hasSecondaryInstructor ? 2 : 1);
export const calcMaxAdditionalParticipants = (obj, hasSecondaryInstructor, def = 0) =>
  (obj?.additionalParticipants?.qty ?? def) * (hasSecondaryInstructor ? 2 : 1);

export const calcMaxAdditionalParticipantsNew = (obj, hasSecondaryInstructor, def = 0) => {
  const max = obj?.additionalParticipants?.qty ?? def;
  if (hasSecondaryInstructor) {
    const maxParticipants = obj?.participants?.max ?? 0;
    return max * 2 + maxParticipants;
  }
  return max;
};

export const calculateCourseDurationLabel = (materialObj, isSkillcheck, skillParticipantsNum) => {
  if (!materialObj || !materialObj?.duration) return '0m';
  const formattedDuration = formatDuration(materialObj.duration);
  const formattedTotalDur = !isSkillcheck
    ? formattedDuration
    : formatDuration(Number(materialObj.duration) * (skillParticipantsNum ?? 0));
  return `${formattedDuration}${isSkillcheck ? ` per participant: ${formattedTotalDur} total` : ''}`;
};

export const calculateOrderChanges = (from, to) => {
  const changesLabels = [];
  if (
    !_.isEqual(
      from?.events?.map((e) => e.course),
      to?.events?.map((e) => e.course),
    )
  ) {
    if (from?.events?.length > to?.events?.length) {
      changesLabels.push('Course Removed');
    } else if (from?.events?.length < to?.events?.length) {
      changesLabels.push('Course Added');
    } else {
      changesLabels.push('Course Changed');
    }
  }

  const existentCourses = from?.events ?? [];
  const existentIds = existentCourses.map((e) => e.id);
  const existentCoursesNew = to?.events?.filter((e) => existentIds.includes(e.id));
  if (!_.isEqual(existentCourses?.addOns ?? [], existentCoursesNew?.addOns ?? [])) {
    changesLabels.push('Add On Item Changed');
  }
  if (!_.isEqual(from?.contactID, to?.contactID) || !_.isEqual(from?.temporaryContact, to?.temporaryContact)) {
    changesLabels.push('Contact Changed');
  }
  const originalTotal = existentCourses.reduce(
    (tot, e) => tot + calculateEventTotalPrice(e, e.addOns ?? [], mapMaterials([e.courseObj])),
    0,
  );
  const newTotal = (to?.events ?? []).reduce(
    (tot, e) => tot + calculateEventTotalPrice(e, e.addOns ?? [], mapMaterials([e.courseObj])),
    0,
  );
  if (originalTotal !== newTotal) {
    changesLabels.push('Pricing Changed');
  }

  return changesLabels;
};

export const showTresholdReachedPopup = (dispatch) => {
  const modalName = 'TRESHOLD_DIALOG';
  dispatch(
    showModal(modalName, {
      modalType: 'FAS_CONFIRM_ALERT',
      modalProps: {
        bodyTextStyle: { fontSize: 18 },
        hideCancel: true,
        disableBackdropClick: false,
        maxWidth: 'sm',
        title: 'TOO MANY RESULTS',
        content: (
          <SimpleText
            divStyle={{ margin: 20 }}
            txt={'Your search produced too many results.\nNarrow down the search by adding more filters and try again.'}
          />
        ),
        confirmText: 'OK',
        onConfirm: () => {
          dispatch(hideModal(modalName));
        },
      },
    }),
  );
};

export const showSendConfirmationPopup = async (dispatch, token, orderId, mode) => {
  const modalName = 'SEND_CONFIRMATION_DIALOG';

  const showToast = (success, msg, warning) => {
    dispatch(
      showModal('BOOK_QUOTE_STATUS', {
        modalType: warning ? 'WARNING_ALERT' : success ? 'SUCCESS_ALERT' : 'ERROR_ALERT',
        modalProps: {
          message: msg ?? '',
        },
      }),
    );
  };

  const disableEmailCheck = !token;
  const orderInfo = orderId && (await dispatch(fetchOrderSummary(orderId)));

  dispatch(
    showModal(modalName, {
      modalType: 'FAS_EVENT_DIALOG',
      modalProps: {
        bodyTextStyle: { fontSize: 18 },
        hideCancel: disableEmailCheck,
        cancelText: 'NO',
        confirmText: disableEmailCheck ? 'OK' : 'YES',
        disableBackdropClick: true,
        maxWidth: 'sm',
        title: 'SEND COURSE CONFIRMATION',
        content: <ConfirmationEmailPopup token={token} mode={mode} customer={orderInfo?.customerObj} />,
        onConfirm: async () => {
          if (!disableEmailCheck) {
            const result = await dispatch(sendConfirmation(token, orderId));
            showToast(result, result ? 'Email confirmation sent succesfully.' : 'Failed to send email confirmation.');
            if (result) dispatch(fetchOrders());
          }
          dispatch(hideModal(modalName));
        },
      },
    }),
  );
};

export const getHigherAccountNumber = (customer) => {
  if (customer?.national_customer) return customer.national_customer;
  if (customer?.regional_customer) return customer.regional_customer;
  if (customer?.local_customer) return customer.local_customer;
  return null;
};

export const normalizeInstructor = (instr) => ({
  first_name: instr?.first_name ?? instr?.firstName ?? '',
  last_name: instr?.last_name ?? instr?.lastName ?? '',
});

export const buildSelectedEvent = (order, tz) => ({
  id: order?.events?.[0]?.id,
  title: (order?.events?.[0]?.title && _.startCase(_.toLower(order?.events?.[0]?.title))) || 'On-Site Class',
  start: getNewDate(moment.tz(order?.events?.[0]?.startTime, tz)),
  end: getNewDate(moment.tz(order?.events?.[0]?.endTime, tz)),
  instructorID: order?.instructorID,
  desc: order?.events?.[0],
});

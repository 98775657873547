/* eslint-disable import/prefer-default-export */
import { baseInstance } from './instances/baseInstance';

export const getAllCustomers = (salesOffice) => baseInstance.get(`/sap/customers?salesoffice=${salesOffice}`);

export const getCustomerBySoldTo = (soldTo) => baseInstance.get(`/sap/customers?soldto=${soldTo}`);

export const searchCustomerCount = (payload) => baseInstance.post('/customerSearchCount', payload);

export const searchCustomer = (payload) => baseInstance.post('/customerSearch', payload);

export const fetchCustomerQuotes = (customerID, page, status) =>
  baseInstance.get(`/customerQuotes?customer=${customerID}&page=${page}${status ? `&status=${status}` : ''}`);

export const fetchCustomerQuoteById = (customerID, quoteID, anonymous) =>
  baseInstance.get(
    anonymous
      ? `/customerQuotes/${customerID}/emailQuotes/${quoteID}`
      : `/customerQuotes/${customerID}/quotes/${quoteID}`,
  );

export const createCustomerQuote = (customerID, payload) =>
  baseInstance.post(`/createCustomerQuote?customer=${customerID}`, payload);

export const updateCustomerQuoteStatus = (customer, quote, status) =>
  baseInstance.put(`/changeQuoteStatus?customer=${customer}&quoteID=${quote}&status=${status.toUpperCase()}`);

export const emailCustomerQuote = ({ email, customer, customerName, quote, createdByEmail, createdByName }) =>
  baseInstance.post(
    `/emailQuote?customer=${customer}&customerName=${customerName}&email=${email}&quote=${quote}&createdByEmail=${createdByEmail}&createdBy=${createdByName}`,
  );

export const fetchCustomerOrders = (customer, page, upcoming) =>
  baseInstance.get(`/ordersForCustomer?customer=${customer}&page=${page}&upcoming=${Boolean(upcoming).toString()}`); // TODO: update endpoint when backend is ready

export const getContactById = (customerID, contactID) =>
  baseInstance.get(`/contacts?customerID=${customerID}&contactID=${contactID}`);

import AddIcon from '@material-ui/icons/Add';
import _ from 'lodash';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { hideModal, showModal } from '../../redux/actions/modals.actions';
import CustomAddonsDialog from '../Calendar/CalendarComponents/EventDialog/CustomAddonsDialog/CustomAddonsDialog';
import ContainerItem from '../LayoutBuilders/ContainerItem';
import StyledButton from './StyledButton';

const AddCustomAddonButton = ({
  onAdd,
  onsite,
  courseIdx,
  saveData,
  dataPath,
  customer,
  bookingMode,
  eventType,
  disabled,
  marginTop,
  marginLeft,
  minWidth,
  selectedLocation,
}) => {
  const dispatch = useDispatch();

  const showCustomAdonDialog = () => {
    const modalName = 'ADD_ON_CUSTOM_MODAL';
    dispatch(
      showModal(modalName, {
        modalType: 'FAS_CONFIRM_ALERT',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: true,
          disableBackdropClick: true,
          maxWidth: 'md',
          title: 'SELECT COURSE MATERIAL',
          content: (
            <CustomAddonsDialog
              mode={bookingMode}
              eventType={eventType}
              courseIdx={courseIdx}
              course={onsite}
              customer={customer}
              saveData={saveData}
              dataPath={dataPath}
              selectedLocation={selectedLocation}
              onAddCustomAddon={(addon, pricing) => {
                onAdd(addon, pricing);
                dispatch(hideModal(modalName));
              }}
            />
          ),
        },
      }),
    );
  };

  return (
    <ContainerItem
      flex={4}
      style={{
        marginLeft,
        minWidth: minWidth ?? 220,
        marginTop,
      }}
    >
      <StyledButton
        variant="contained"
        color="primary"
        handleButton={() => showCustomAdonDialog()}
        buttonContent="Add custom add-on"
        startIcon={<AddIcon />}
        disabled={disabled}
      />
    </ContainerItem>
  );
};

export default _.flow([connect()])(AddCustomAddonButton);

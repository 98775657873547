/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import _ from 'lodash';
import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
import { Box, Divider } from '@material-ui/core';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { useSelector } from 'react-redux';
import { arrayPush, change, Field } from 'redux-form';
import AddCustomAddonButton from '../../../components/Button/AddCustomAddonButton';
import StyledButton from '../../../components/Button/StyledButton';
import CoursePriceDialog from '../../../components/Calendar/CalendarComponents/CoursePriceDialog/CoursePriceDialog';
import { adjustRowPrice } from '../../../components/Calendar/CalendarComponents/EventDialog/EventDialogForm/FormComponent/CartSummaryForm';
import FASBlackFont from '../../../components/Forms/CustomFormComponents/FASBlackFont';
import MDTextInputField from '../../../components/Forms/FormComponents/MDTextInput/MDTextInputField';
import Container from '../../../components/LayoutBuilders/Container';
import ContainerItem from '../../../components/LayoutBuilders/ContainerItem';
import * as ModalsActions from '../../../redux/actions/modals.actions';
import { materialsDataSelector, materialSelectorOfMultipleTypes } from '../../../redux/selectors/materials.selector';
import { listingsSelector } from '../../../redux/selectors/sap.selectors';
import { selectedTciSelector, userInfoSelector } from '../../../redux/selectors/user.selectors';
import { timezoneSelector } from '../../../redux/selectors/utils.selectors';
import {
  BOOKING_MODE,
  COLORS,
  DEFAULT_TRUCK_NUMBER,
  ENTER_KEY_CODE,
  MATERIAL_TYPE,
  SUBCONTRACTED_INSTRUCTOR_LABEL,
  TIME_FORMAT,
} from '../../../utils/consts';
import { calculateCourseDurationLabel, resetDeliverySignature } from '../../../utils/event.utils';
import { ccyFormat, removeLeadingZeros } from '../../../utils/helpers';
import AddOnTableItem from './AddOnTableItem';
import RefreshPriceBtn from './RefreshPriceBtn';

const priceListingsFormPath = 'priceListings';

const getTotalPrice = (isSkillCheck = false, unitPrice = 0, participantsCount = 0, makeDayDiscount = 0) => {
  if (!isSkillCheck) {
    return unitPrice + (makeDayDiscount || 0);
  }

  return unitPrice * participantsCount + (makeDayDiscount || 0);
};

const AddOnItemForm = ({
  i,
  withDivider,
  classes,
  allowEditing,
  event,
  customer,
  showInstructor,
  formName,
  showMaterialNumbers,
  dispatch,
  customerFacing,
  isQuote,
  tzOverride,
  isBilling,
  showMADDiscount,
}) => {
  // const values = useSelector((state) => (allowEditing ? state.form[formName]?.values : event));
  const course = useSelector((state) =>
    customerFacing ? event : state.form[formName]?.values.billingSummary.events[i],
  );
  const subcontracted = course?.subcontracted;
  const coursesNum = useSelector(
    (state) =>
      state.form[formName]?.values.billingSummary?.events?.filter((e) => e.courseObj.type === MATERIAL_TYPE.COURSE)
        .length ?? 1,
  );
  const isSkillcheck = course?.courseObj?.type === MATERIAL_TYPE.SKILL_CHECK;
  const unitPrice = Number(course.price?.amount ?? 0);
  const madDiscount = Number(course?.price?.makeDayDiscount || 0);
  const totalPrice = getTotalPrice(isSkillcheck, unitPrice, course.participants_count, madDiscount);
  const courseUnitPriceStr = `${course.price ? ccyFormat(unitPrice, course.price.currency) : ''}`;
  const coursePriceStr = `${course.price ? ccyFormat(totalPrice, course.price.currency) : ''}`;
  const selectedTci = useSelector(selectedTciSelector);
  const selectedTimezone = useSelector((state) => tzOverride?.value ?? timezoneSelector(state));
  const defaultTruckNum = useSelector((state) => ((selectedTci || userInfoSelector(state)) ?? {}).defaultTruckNumber);
  const addOnsFormPath = `billingSummary.events[${i}].addOns`;
  const addOnsMaterialFormPath = `billingSummary.events[${i}].courseObj.addons`;
  const courseFormPath = `billingSummary.events[${i}]`;
  const priceFieldId = `${courseFormPath}.price`;
  const truckNumFieldId = `${courseFormPath}.truckNumber`;
  const titleFlex = isSkillcheck ? 4 : 6;
  const instructorFlex = 2;
  const participantsFlex = 2;
  const skUnitPriceFlex = 2;
  const priceFlex = 2;
  const instructorLabel = subcontracted
    ? SUBCONTRACTED_INSTRUCTOR_LABEL
    : course?.instructor
      ? `${course?.instructor?.firstName ?? ''} ${course?.instructor?.lastName ?? ''}`
      : '';

  const exclusionListings = useSelector(listingsSelector);

  const showList = course.addOns && !!course.addOns.length;
  const haveExtraParticipants =
    course.additional_participants &&
    !!course.additional_participants.length &&
    course.additional_participants[0].id &&
    course.additional_participants[0].qty > 0;
  const allowCustomAddons = course?.courseObj?.type === MATERIAL_TYPE.COURSE;
  const [valid, setValid] = React.useState(false);

  const materialsInfo = useSelector(materialsDataSelector);
  const getMaterialInfo = (id, types) =>
    materialSelectorOfMultipleTypes({
      id,
      materialsInfo,
      types: types ?? [MATERIAL_TYPE.COURSE],
    });
  const getWarehouseOverride = (item, types) => getMaterialInfo(item.id, types)?.warehouseOverride;

  React.useEffect(() => {
    if (allowEditing && defaultTruckNum) {
      if (!course.truckNumber) {
        dispatch(change(formName, truckNumFieldId, defaultTruckNum));
      }
      (course.addOns ?? []).forEach((addon, aIdx) => {
        if (!addon.truckNumber) {
          dispatch(change(formName, `${addOnsFormPath}[${aIdx}].truckNumber`, defaultTruckNum));
        }
      });
    }
    return () => {};
  }, [allowEditing, course?.addOns?.length ?? null]);

  const _adjustRow = ({ pid, add, remove }) => {
    adjustRowPrice({
      pid,
      add: add ? Number(add) : undefined,
      subtract: remove ? Number(remove) : undefined,
    });
  };

  const adjustSummaryPrice = ({ add, remove }) => {
    _adjustRow({ pid: `${isSkillcheck ? 'skillchecks' : 'course'}${course.order}`, add, remove });
  };

  const adjustAddonsPrice = ({ add, remove }) => {
    _adjustRow({ pid: `addons${course.order}`, add, remove });
  };

  const updateCoursePrice = (newPrice) => {
    const oldVal = (course.price?.amount ?? 0) * (isSkillcheck ? course.participants_count : 1);
    dispatch(change(formName, priceFieldId, { ...(newPrice ?? {}) }));
    resetDeliverySignature(formName, dispatch); // reset signature after price change
    adjustSummaryPrice({
      remove: oldVal,
      add: isSkillcheck ? Number(newPrice?.amount ?? 0) * course.participants_count : newPrice?.amount,
    });
  };

  const addCustomAddon = (toAdd, pricing) => {
    const { id, title, code } = toAdd;
    const notAvailable = !((_.find(exclusionListings ?? [], { Material: code }) ?? {}).inlcuded ?? true);
    const count = notAvailable ? 0 : Number(course.participants_count ?? 0);
    const singlePrice = Number(pricing.amount ?? 0.0);
    dispatch(arrayPush(formName, `${addOnsMaterialFormPath}.optional`, id));
    dispatch(
      arrayPush(formName, addOnsFormPath, {
        id,
        title,
        sap_material_number: code,
        qty: count,
        price: {
          amount: singlePrice,
          currency: pricing.currency,
        },
        required: false,
        include: !notAvailable,
        truckNumber: toAdd.warehouseOverride ? DEFAULT_TRUCK_NUMBER : '',
      }),
    );
    adjustAddonsPrice({ add: count * singlePrice });
  };

  const openCoursePricePopup = () => {
    const modalName = 'COURSE_PRICE_DIALOG';
    dispatch(
      ModalsActions.showModal(modalName, {
        modalType: 'FAS_CONFIRM_ALERT',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: true,
          confirmText: 'confirm',
          fullWidth: true,
          disableBackdropClick: true,
          maxWidth: 'sm',
          title: `${course.title ?? ''} Price`,
          content: (
            <CoursePriceDialog
              modalName={modalName}
              course={course}
              coursesNum={coursesNum}
              customer={customer}
              dispatch={dispatch}
              onSubmit={updateCoursePrice}
            />
          ),
        },
      }),
    );
  };

  const divider = () => <Divider style={{ margin: '30px 0px' }} />;
  const text = (txt, className, weight) => (
    <FASBlackFont size="15px" weight={weight ?? 500} className={className} title={txt} />
  );
  const container = ({ children, ...rest }) => (
    <Container justify="space-between" alignItems="flex-start" spacing={3} {...(rest ?? {})}>
      {children}
    </Container>
  );
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;
  const inputField = ({ id, placeholder, type, label, required, onChange, onDone, width, disabled, error = false }) => (
    <Field
      id={id}
      name={id}
      component={MDTextInputField}
      size="small"
      type={type ?? 'text'}
      disabled={disabled}
      inputProps={{ width: width ?? '50%', style: { textAlign: 'start', marginLeft: 5 } }}
      min="0"
      normalize={
        type === 'number'
          ? (val) => (!val || Number.isNaN(Number(val)) ? val : Number(val) > 0 ? val : `${Math.abs(Number(val))}`)
          : undefined
      }
      variant="outlined"
      required={required}
      label={label}
      noBorderRadius
      style={{ width: width ?? '55%' }}
      placeholder={placeholder}
      onHandleChange={onChange}
      onBlurCapture={() => (onDone ? onDone() : null)}
      onKeyDown={(k) => (k.code === ENTER_KEY_CODE && onDone ? onDone() : null)}
      meta={{ error, invalid: error, touched: error }}
    />
  );

  const getCourseTimeframeLabel = () =>
    `${moment.utc(course.startTime).tz(selectedTimezone).format(TIME_FORMAT)} - ${moment.utc(course.endTime).tz(selectedTimezone).format(TIME_FORMAT)}`;
  const getCourseDurationLabel = () =>
    calculateCourseDurationLabel(course.courseObj, isSkillcheck, course.participants_count);

  const courseHeaders = () =>
    container({
      children: [
        item({
          flex: titleFlex,
          style: { textAlign: 'start' },
          children: text(
            `${isSkillcheck ? 'Skillcheck' : 'Course'}${isSkillcheck && isQuote ? '\n' : ' '}(${isQuote ? getCourseDurationLabel() : getCourseTimeframeLabel()})`,
            undefined,
            600,
          ),
        }),
        item({
          flex: instructorFlex,
          children: showInstructor && text('Instructor', classes.textCenterAlign, 600),
        }),
        isSkillcheck &&
          item({
            flex: skUnitPriceFlex,
            children: text('Unit Price', classes.textCenterAlign, 600),
          }),
        item({
          flex: participantsFlex,
          children: text('Participants #', classes.textCenterAlign, 600),
        }),
        item({
          flex: priceFlex,
          children: text('Price', classes.textRightAlign, 600),
        }),
      ],
    });

  const refreshPriceBtn = (materialNum, listingsPath) => (
    <RefreshPriceBtn
      formName={formName}
      customer={customer}
      classes={classes}
      listingsPath={listingsPath}
      materialNum={materialNum}
      updatePriceFn={updateCoursePrice}
    />
  );

  const priceBtn = ({ child, onClick }) => (
    <>
      <StyledButton
        buttonContent={child}
        handleButton={onClick}
        variant="outlined"
        style={{ color: 'black', boxShadow: 'none', border: `1px solid ${COLORS.CINTAS_GRAY}` }}
      />
    </>
  );

  const truckNumberInput = () => (
    <>
      <Box height={8} />
      {inputField({
        id: truckNumFieldId,
        label: course?.truckNumber ? 'Truck Number *' : null,
        placeholder: course?.truckNumber ? null : 'Truck Number *',
        width: '50%',
        onChange: (value) => dispatch(change(formName, truckNumFieldId, value)),
        disabled: getWarehouseOverride(course),
      })}
    </>
  );

  const updateSkillcheck = (value) => {
    dispatch(change(formName, `${courseFormPath}.participants_count`, Math.abs(Number(value))));
    resetDeliverySignature(formName, dispatch); // reset signature after participants change
    setValid(true);
  };

  const courseInfo = () => {
    const priceListing = course[priceListingsFormPath];
    const disablePriceEdits = Boolean(_.find(priceListing ?? [], { Customer: customer?.sold_to }));
    return container({
      children: [
        item({
          flex: titleFlex,
          style: { textAlign: 'start' },
          children: container({
            style: { paddingLeft: 12 },
            direction: 'column',
            children: [
              text(
                `${showMaterialNumbers && course?.sap_material_number ? `${removeLeadingZeros(course?.sap_material_number)} - ` : ''}${course.title ?? course.courseObj?.title ?? ''}${!customerFacing ? (!allowEditing ? `\nTruck Num: ${course.truckNumber ?? 'N/A'}` : '') : ''}`,
              ),
              allowEditing && !customerFacing && truckNumberInput(),
            ],
          }),
        }),
        item({
          flex: instructorFlex,
          style: { paddingTop: 0 },
          children: showInstructor && text(instructorLabel, classes.textCenterAlign),
        }),
        isSkillcheck &&
          item({
            flex: skUnitPriceFlex,
            style: { paddingTop: 0 },
            className: classes.textCenterAlign,
            children:
              allowEditing && !disablePriceEdits
                ? [
                    priceBtn({
                      onClick: openCoursePricePopup,
                      child: courseUnitPriceStr,
                    }),
                    refreshPriceBtn(course?.sap_material_number, `${courseFormPath}.${priceListingsFormPath}`),
                  ]
                : [
                    text(courseUnitPriceStr, classes.textCenterAlign),
                    ...(allowEditing
                      ? [refreshPriceBtn(course?.sap_material_number, `${courseFormPath}.${priceListingsFormPath}`)]
                      : []),
                  ],
          }),
        item({
          flex: participantsFlex,
          style: { paddingTop: 0 },
          children:
            allowEditing && !disablePriceEdits && isSkillcheck
              ? inputField({
                  name: `${courseFormPath}.participants_count`,
                  id: `${courseFormPath}.participants_count`,
                  type: 'number',
                  width: '50%',
                  error: !valid,
                  onChange: updateSkillcheck,
                })
              : [text(`${course.participants_count}`, classes.textCenterAlign)],
        }),
        item({
          flex: priceFlex,
          style: { paddingTop: 0 },
          className: classes.textRightAlign,
          children:
            allowEditing && !isSkillcheck && !disablePriceEdits
              ? [
                  priceBtn({
                    onClick: openCoursePricePopup,
                    child: coursePriceStr,
                  }),
                  refreshPriceBtn(course?.sap_material_number, `${courseFormPath}.${priceListingsFormPath}`),
                ]
              : [
                  text(coursePriceStr, classes.textRightAlign),
                  ...(!isSkillcheck && allowEditing
                    ? [refreshPriceBtn(course?.sap_material_number, `${courseFormPath}.${priceListingsFormPath}`)]
                    : []),
                ],
        }),
        ...(showMADDiscount
          ? [
              item({
                flex: 10,
                className: classes.textRightAlign,
                children: text(`Make It A Day Discount:`, classes.textRightAlign),
              }),
              item({
                flex: 2,
                className: classes.textRightAlign,
                children: text(ccyFormat(madDiscount), classes.textRightAlign, 'bolder'),
              }),
              item({
                flex: 10,
                className: classes.textRightAlign,
                children: text(`Final Price:`, classes.textRightAlign),
              }),
              item({
                flex: 2,
                className: classes.textRightAlign,
                children: text(ccyFormat(totalPrice - madDiscount), classes.textRightAlign, 'bolder'),
              }),
            ]
          : []),
      ],
    });
  };

  const addOnsHeaders = () =>
    container({
      children: [
        item({
          flex: titleFlex,
          style: { textAlign: 'start' },
          children: text('Course Materials', undefined, 600),
        }),
        item({
          flex: instructorFlex,
          children: text('Unit Price', classes.textCenterAlign, 600),
        }),
        item({
          flex: participantsFlex,
          children: text('Quantity', classes.textCenterAlign, 600),
        }),
        item({
          flex: priceFlex,
          children: text('Price', classes.textRightAlign, 600),
        }),
      ],
    });

  const extraParticipantsHeaders = () =>
    container({
      children: [
        item({
          flex: titleFlex,
          style: { textAlign: 'start' },
          children: text('Additional Participants', undefined, 600),
        }),
        item({
          flex: instructorFlex,
          children: text('Unit Price', classes.textCenterAlign, 600),
        }),
        item({
          flex: participantsFlex,
          children: text('Quantity', classes.textCenterAlign, 600),
        }),
        item({
          flex: priceFlex,
          children: text('Price', classes.textRightAlign, 600),
        }),
      ],
    });

  const customAddonButton = () =>
    allowCustomAddons ? (
      item({
        flex: 12,
        style: { marginTop: showList ? 24 : 6, textAlign: 'start' },
        children: (
          <AddCustomAddonButton
            onAdd={addCustomAddon}
            saveData={(data) => {
              dispatch(arrayPush(formName, 'customAddonOptions', data));
            }}
            dataPath={`${formName}.values.customAddonOptions`}
            onsite={course}
            courseIdx={i}
            customer={customer}
            bookingMode={BOOKING_MODE.editing}
            eventType={course.eventType}
            marginLeft={showList ? 8 : 0}
            isBilling={isBilling}
          />
        ),
      })
    ) : (
      <></>
    );

  const addOnsTable = () => (
    <>
      {addOnsHeaders()}
      {_.map(course.addOns ?? [], (addon, idx) => (
        <AddOnTableItem
          formName={formName}
          course={course}
          customer={customer}
          addon={addon}
          idx={idx}
          currency={course.price.currency}
          allowEditing={allowEditing}
          showMaterialNumbers={showMaterialNumbers}
          customerFacing={customerFacing}
          i={i}
          tzOverride={tzOverride}
          exclusionListings={exclusionListings}
          flexMap={{
            titleFlex,
            instructorFlex,
            participantsFlex,
            priceFlex,
          }}
          isBilling={isBilling}
        />
      ))}
      {allowEditing ? customAddonButton() : <></>}
    </>
  );

  const extraParticipantsTable = () => (
    <>
      {extraParticipantsHeaders()}
      {_.map(course.additional_participants ?? [], (addPar, idx) => (
        <AddOnTableItem
          formName={formName}
          course={course}
          customer={customer}
          addon={addPar}
          idx={idx}
          currency={course.price.currency}
          // allowEditing={false}
          allowEditing={allowEditing}
          showMaterialNumbers={showMaterialNumbers}
          customerFacing={customerFacing}
          exclusionListings={exclusionListings}
          isAdditionalParticipants
          i={i}
          flexMap={{
            titleFlex,
            instructorFlex,
            participantsFlex,
            priceFlex,
          }}
          defaultQty={course.participants_count ?? 0}
        />
      ))}
    </>
  );

  return (
    <>
      {courseHeaders()}
      {courseInfo()}
      {haveExtraParticipants && extraParticipantsTable()}
      {showList && addOnsTable()}
      {!showList && allowEditing ? customAddonButton() : <></>}
      {withDivider && divider()}
    </>
  );
};

export default AddOnItemForm;

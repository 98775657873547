/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, submit } from 'redux-form';
import { BOOKING_MODE, EVENT_TYPE } from '../../../../../utils/consts';
import AddOnPageForm from './AddonPageForm';
import OnsiteSubForm from './OnsiteSubForm';

const OnsiteForm = ({ activeStep, mode, genericQuote, selectedLocation }) => {
  // const [activeStep, setActiveStep] = React.useState(0);
  const getOnsitCourseContent = (step) => {
    switch (step) {
      case 0:
        return <OnsiteSubForm mode={mode} genericQuote={genericQuote} selectedLocation={selectedLocation} />;
      case 1:
        return (
          <AddOnPageForm
            mode={mode}
            eventType={mode === BOOKING_MODE.quote ? EVENT_TYPE.QUOTE : EVENT_TYPE.ON_SITE}
            selectedLocation={selectedLocation}
            genericQuote={genericQuote}
          />
        );
      default:
        return 'Unknown step';
    }
  };
  return getOnsitCourseContent(activeStep);
};

// export default OnsiteForm;
export default _.flow([
  connect(),
  reduxForm({
    form: 'AddEventDialog',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    // validate,
    initialValues: {},
    onSubmit: submit,
  }),
])(OnsiteForm);

export default class Completer {
  promise;

  complete;

  reject;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.complete = resolve;
      this.reject = reject;
    });
  }
}

/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
import { Button, Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import ArrowUpwardIcon from '@mui/icons-material/North';
import ArrowDownwardIcon from '@mui/icons-material/South';
import _ from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { connect, useSelector } from 'react-redux';
import { change, getFormValues, submit } from 'redux-form';
import Immutable from 'seamless-immutable';
import { v4 as uuidv4 } from 'uuid';
import * as EventsActions from '../../redux/actions/events.actions';
import * as ModalsActions from '../../redux/actions/modals.actions';
import * as OrdersActions from '../../redux/actions/orders.actions';
import { blockDay } from '../../redux/actions/users.actions';
import {
  calendarTypeSelector,
  openCancelledEventsPopoverSelector,
  viewportSelector,
} from '../../redux/selectors/calendar.selectors';
import { cancelledEventsCountSelector, unblockedDaysSelector } from '../../redux/selectors/events.selectors';
import { holidaysSelector, showRevenueSelector } from '../../redux/selectors/settings.selectors';
import { hasUserAccessSelector, selectedTciSelector, userInfoSelector } from '../../redux/selectors/user.selectors';
import { isTimeBlocked, isTimeRangeBlocked } from '../../utils/calendar';
import {
  BOOKING_MODE,
  CALENDAR_TYPE,
  CALENDAR_VIEWS,
  COLORS,
  DATE_PICKER_FOMAT,
  defaultView,
  EVENT_CALENDAR_COLORS,
  EVENT_TYPE,
  MATERIAL_TYPE,
  ROLE_ACCESSES,
  TIME_BLOCK_INTERVALS,
  TIME_BLOCK_REASONS,
  TIME_BLOCK_TYPE,
} from '../../utils/consts';
import DateUtils, { isHoliday } from '../../utils/dateUtils';
import { showSendConfirmationPopup } from '../../utils/event.utils';
import { hasEarlyMorningEvents, hasLateNightEvents, middleDate } from '../../utils/helpers';
import RowCell from '../../utils/LayoutBuilders/RowCell';
import RowLayout from '../../utils/LayoutBuilders/RowLayout';
import SimpleText from '../Text/SimpleText';
import CalendarCellWrapper from './CalendarCellWrapper';
import InstructorsCards from './CalendarComponents/Cards/InstructorsCards';
import EventDetailsDialog from './CalendarComponents/EventDetailsDialog/EventDetailsDialog';
import EventDialog from './CalendarComponents/EventDialog/EventDialog';
import { cleanRows } from './CalendarComponents/EventDialog/EventDialogForm/FormComponent/CartSummaryForm';
import EventPopper from './CalendarComponents/EventPopper/EventPopper';
import PersonalCalendarDialog from './CalendarComponents/PersonalCalendarDialog/PersonalCalendarDialog';
import UnblockDayDialog from './CalendarComponents/UnblockDayDialog/UnblockDayDialog';
import './CalendarTable.css';
import CancelledEventsHeader from './CancelledEventsHeader';
import CustomToolbar from './CustomToolbar';
import DayRevenueLabel from './DayRevenueLabel';
import DayView from './DayView/DayView';
import SubcontractedEventWrapper from './LocationsCalendar/SubcontractedEventWrapper';
import OptionToolbar from './OptionToolbar';
import CustomEventWrapper from './WeekView/CustomEventWrapper';
import Year from './YearView/YearView';

const CURRENT_DATE = moment.utc().toDate();
const calendarHeight = 'calc(100vh - 280px)';

const CalendarTable = (props) => {
  const {
    events,
    materialsInfo,
    cancelledEventsInfo,
    hideOptionsBar,
    utils: {
      tz: { value: selectedTimezone },
    },
    dispatch,
    disableEventCreation,
    disableEditing,
    showRevenue,
    onEventEdited,
    loading,
    settingsView,
    loadViewportEvents,
    currentViewport,
    forceInstructorDisplay,
    shouldOpenCancelledOrdersPopover,
    calendarType,
    formName,
    configHolidays,
    currentTCI,
  } = props;
  const [transformedEvents, setTransformedEvents] = useState([]);
  const [calendarView, setCalendarView] = useState(settingsView);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedEvent, setSelectedEvent] = React.useState(null);
  const initialDateState =
    currentViewport?.start && currentViewport?.end
      ? middleDate(currentViewport.start, currentViewport?.end).toDate()
      : CURRENT_DATE;
  const [selectedDate, setSelectedDate] = React.useState(initialDateState);
  const openPopper = Boolean(anchorEl);
  const localizer = momentLocalizer(moment);
  const viewsTcisCalendar = useSelector((state) => !hasUserAccessSelector(state, [ROLE_ACCESSES.ownCalendar]));
  const unblockedDays = useSelector(unblockedDaysSelector);
  const currentUser = useSelector(viewsTcisCalendar ? selectedTciSelector : userInfoSelector);

  const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  };

  useEffect(() => {
    if (events === null) {
      return [];
    }
    let newEvents = [];
    _.map(events, (event) => {
      const { id } = event;
      let newEvent = {};
      if (!event) {
        newEvent = {
          ...event,
        };
      } else if (event.eventType === EVENT_TYPE.TIME_BLOCK) {
        const startdate = event.allDay
          ? DateUtils.copyLocalDateToTimezone(DateUtils.getNewDate(moment.utc(event.startTime)), selectedTimezone)
          : moment.utc(event.startTime).tz(selectedTimezone);
        const enddate = event.allDay
          ? DateUtils.copyLocalDateToTimezone(DateUtils.getNewDate(moment.utc(event.endTime), 12), selectedTimezone)
          : moment.utc(event.endTime).tz(selectedTimezone);
        newEvent = {
          id,
          title: TIME_BLOCK_REASONS[event.reason],
          start: DateUtils.getNewDate(startdate),
          end: DateUtils.getNewDate(enddate),
          allDay: event.allDay || false,
          desc: _.omit(event, ['title, id']),
        };
      } else if (event.eventType === EVENT_TYPE.ON_SITE) {
        const startdate = moment.tz(event.startTime, selectedTimezone);
        const enddate = moment.tz(event.endTime, selectedTimezone);
        newEvent = {
          id,
          title: (event.title && _.startCase(_.toLower(event.title))) || 'On-Site Class',
          start: DateUtils.getNewDate(startdate),
          end: DateUtils.getNewDate(enddate),
          desc: _.omit(event, ['title, id']),
        };
      } else if (event.eventType === EVENT_TYPE.OPEN_ENROLLMENT) {
        const course = materialsInfo[MATERIAL_TYPE.COURSE][event.course] ?? {};
        const startdate = moment.tz(event.startTime, selectedTimezone);
        const enddate = moment.tz(event.endTime, selectedTimezone);
        newEvent = {
          id,
          title: (course && course.title) || 'Open Enrollment Class',
          start: DateUtils.getNewDate(startdate),
          end: DateUtils.getNewDate(enddate),
          desc: _.omit(event, ['title, id']),
        };
      }
      newEvents = newEvents.concat(newEvent);
    });

    if (cancelledEventsInfo && calendarView === Views.MONTH && calendarType === CALENDAR_TYPE.tci) {
      Object.keys(cancelledEventsInfo).forEach((key) => {
        const info = cancelledEventsInfo[key];
        if ((info.cancelledEvents ?? 0) === 0) return;
        const date = moment.utc(key).startOf('day');
        newEvents.push({
          allDay: true,
          cancelled: true,
          title: 'Cancelled Course(s)',
          start: DateUtils.getNewDate(date),
          end: DateUtils.getNewDate(date.add(1, 'minute')),
          desc: { ...info, eventType: EVENT_TYPE.CANCELLED_EVENT },
        });
      });
    }
    setTransformedEvents(newEvents);
    return newEvents;
  }, [events, selectedTimezone]);

  const getAllDayEventsForDate = (date) => DateUtils.getAllDayEventsForDate(date, events, selectedTimezone);
  const showingBlockedDayIcon = (date) => date?.getDay() === 0 || isHoliday(date, configHolidays);
  const eventStartTimes = _.map(transformedEvents, (e) => e.start);

  const selectEventHandler = (SelectedEvent, e) => {
    if (SelectedEvent.cancelled) {
      if (
        !shouldOpenCancelledOrdersPopover ||
        (shouldOpenCancelledOrdersPopover && shouldOpenCancelledOrdersPopover !== SelectedEvent.desc?.date)
      ) {
        openCancelledOrdersPopover(SelectedEvent.desc?.date);
      } else {
        openCancelledOrdersPopover(false);
      }
      return;
    }

    setAnchorEl(anchorEl ? null : e.currentTarget);
    setSelectedEvent({
      ...SelectedEvent,
      start: DateUtils.copyLocalDateToTimezone(SelectedEvent.start, selectedTimezone).tz('UTC'),
      end: DateUtils.copyLocalDateToTimezone(SelectedEvent.end, selectedTimezone).tz('UTC'),
    });
  };

  const popperCloseHandler = () => {
    setAnchorEl(null);
  };

  const changViewHandler = (view) => {
    setCalendarView(view);
    openCancelledOrdersPopover(false);
    dispatch(change(formName, 'view', view));
    loadViewportEvents(DateUtils.copyLocalDateToTimezone(selectedDate, selectedTimezone), view);
  };

  const navigateHandler = (date, selectView) => {
    setSelectedDate(date);
    openCancelledOrdersPopover(false);
    const dateToTz = DateUtils.copyLocalDateToTimezone(date, selectedTimezone);
    if (selectView) {
      //  Selecting date from YEAR view, was using wrong viewport dates sometimes, so
      // this changes the view and assures that is using the correct dates to switch to WEEK view
      setCalendarView(selectView);
      dispatch(change(formName, 'view', selectView));
      loadViewportEvents(dateToTz, selectView);
      return;
    }
    loadViewportEvents(dateToTz, calendarView);
  };

  const onSubmitCourse = async (values) => {
    const allDay =
      values.eventType === EVENT_TYPE.TIME_BLOCK && values.reason === TIME_BLOCK_REASONS.PTO && values.allDay;
    const start = moment.utc(allDay ? values.date : values.startTime);
    const end = moment.utc(allDay ? values.endDate : values.endTime);
    if (
      isTimeRangeBlocked({
        unblockedDays,
        timezone: selectedTimezone,
        start: moment(allDay ? values.date : values.startTime)
          .tz(selectedTimezone)
          .toDate(),
        end: moment(allDay ? values.endDate : values.endTime)
          .tz(selectedTimezone)
          .toDate(),
      })
    ) {
      dispatch(
        ModalsActions.showModal('CREATE_EVENT_ERROR', {
          modalType: 'ERROR_ALERT',
          modalProps: { message: 'Cannot create an event on a blocked time. Please, try again.' },
        }),
      );
      return;
    }
    const { communication } = values;
    let series;
    if (values.recurringSeries && values.recurringSeries.interval !== TIME_BLOCK_INTERVALS.NO_REPEAT) {
      const daysDiff = moment(values.recurringSeries.endDate).diff(moment(values.recurringSeries.startDate), 'days');
      series = values.recurringSeries &&
        values.recurringSeries.interval !== TIME_BLOCK_INTERVALS.NO_REPEAT && {
          ...values.recurringSeries,
          seriesID: uuidv4(),
          startDate: moment.utc(values.startTime).startOf('day').toISOString(),
          endDate: moment.utc(values.startTime).endOf('day').add(daysDiff, 'days').toISOString(),
          interval: values.recurringSeries.interval.toUpperCase(),
        };
    }

    try {
      dispatch(submit('AddEventDialog'));
      const response = await dispatch(
        EventsActions.createEvent(start, end, { ...values, allDay, ...(series && { series }) }),
      );
      if (values.eventType === EVENT_TYPE.ON_SITE) {
        const eventInfo = await dispatch(EventsActions.fetchEventById(response.data.eventIDs?.[0], true));
        const editingModes = [BOOKING_MODE.editing, BOOKING_MODE.rescheduling];
        const shouldShowEmailConfirmation = !editingModes.includes(values.bookingMode) || values.confirmationsentDate;
        if (shouldShowEmailConfirmation) {
          showSendConfirmationPopup(dispatch, response?.data?.emailToken, eventInfo.order, values.bookingMode);
        }
      }
      dispatch(ModalsActions.hideModal('ADD_EVENT_DIALOG'));
      cleanRows();
      dispatch(
        ModalsActions.showModal('CREATE_EVENT_SUCCESS', {
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: communication
              ? 'Event successfully created! Order confirmation email sent to customer!'
              : 'Event successfully created!',
          },
        }),
      );
      if (values.addExternalCalendar) {
        const eventIds = (response?.data ?? {}).eventIDs ?? [];
        const eventId = (_.isEmpty(eventIds) ? [''] : eventIds)[0];
        showAddToCalendarPopup(eventId);
      }
      dispatch(OrdersActions.fetchOrders());
    } catch (error) {
      let message = 'Something happened while creating the event. Please, try again!';
      if (error instanceof EventsActions.EventOverlapError) {
        message = error.message;
      }
      dispatch(
        ModalsActions.showModal('CREATE_EVENT_ERROR', {
          modalType: 'ERROR_ALERT',
          modalProps: { message },
        }),
      );
    }
  };

  const showAddToCalendarPopup = async (eventId) => {
    const event = await dispatch(EventsActions.fetchEventById(eventId));
    if (!event) return;
    const orderId = event.order;
    const modalName = 'ADD_TO_CAL_POPUP';
    dispatch(
      ModalsActions.showModal(modalName, {
        modalType: 'FAS_CONFIRM_ALERT',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: true,
          disableBackdropClick: false,
          maxWidth: 'sm',
          title: 'ADD TO PERSONAL CALENDAR',
          content: <PersonalCalendarDialog dispatch={dispatch} modalName={modalName} orderId={orderId} />,
          confirmText: 'OK',
          onConfirm: () => {
            dispatch(ModalsActions.hideModal(modalName));
          },
        },
      }),
    );
  };

  const showAddEventDialog = (info) => {
    const { start, end } = info ?? {};
    const modalName = 'ADD_EVENT_DIALOG';
    dispatch(
      ModalsActions.showModal('ADD_EVENT_DIALOG', {
        modalType: 'FAS_EVENT_DIALOG',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: true,
          confirmText: 'confirm',
          // fullWidth: true,
          disableBackdropClick: true,
          maxWidth: 'lg',
          title: 'ADD EVENT',
          draggable: true,
          nestedScrolling: true,
          content: (
            <EventDialog
              modalName={modalName}
              onSubmit={onSubmitCourse}
              updatedEvent={null}
              mode={BOOKING_MODE.booking}
              initialDateValues={{ start, end }}
            />
          ),
        },
      }),
    );
  };

  const showEventDetailsDialog = (fromStartDelivery, event) => {
    popperCloseHandler();
    const modalName = 'EVENT_DETAILS_DIALOG';
    dispatch(
      ModalsActions.showModal(modalName, {
        modalType: 'FAS_EVENT_DIALOG',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: true,
          disableBackdropClick: true,
          maxWidth: 'lg',
          title: 'EVENT DETAILS',
          nestedScrolling: true,
          content: (
            <EventDetailsDialog
              openedFromStartDelivery={fromStartDelivery}
              modalName={modalName}
              dispatch={dispatch}
              orderId={(event ?? selectedEvent?.desc)?.order}
            />
          ),
        },
      }),
    );
  };

  const openCancelledOrdersPopover = (formattedDate) => {
    dispatch(change(formName, 'openCancelledEventsPopover', formattedDate));
  };

  const showUnblockDayDialog = (day) => {
    const modalName = 'UNBLOCK_DAY_DIALOG';
    dispatch(
      ModalsActions.showModal(modalName, {
        modalType: 'FAS_EVENT_DIALOG',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: true,
          disableBackdropClick: true,
          maxWidth: 'lg',
          title: 'Unblock Day',
          nestedScrolling: true,
          content: <UnblockDayDialog day={day} dispatch={dispatch} />,
        },
      }),
    );
  };

  const handleCalSlotClick = (info) => {
    const { start, end } = info ?? {};
    const cancelledOrdersIndex = moment(start).format(DATE_PICKER_FOMAT);
    // const startMoment = moment(start);
    const endMoment = moment(end);

    /*
     * if info.start date is same as info.end date and if both info.start & info.end have 00:00 time,
     * it means the user clicked on the header, if there are any cancelled events for that day,
     * open the cancelled events popup for that day.
     */
    const isWeekend = [0, 6].includes(start.getDay());
    if (
      start.getDate() === endMoment.add(-1, 'day').toDate().getDate() &&
      start.getHours() === 0 &&
      end.getHours() === 0 &&
      (calendarView === Views.WEEK || calendarView === Views.DAY)
    ) {
      if (shouldOpenCancelledOrdersPopover !== cancelledOrdersIndex) {
        openCancelledOrdersPopover(cancelledOrdersIndex);
      } else {
        openCancelledOrdersPopover(false);
      }
      // Unblock / Lock logic
      if (isWeekend && !viewsTcisCalendar) {
        const found = unblockedDays?.find((day) => day.date === moment(start).format('YYYY-MM-DD'));
        if (found) {
          const modalName = 'BLOCK_DAY_CONFIRM';
          dispatch(
            ModalsActions.showModal(modalName, {
              modalType: 'FAS_CONFIRM_ALERT',
              modalProps: {
                bodyTextStyle: { fontSize: 18 },
                hideCancel: false,
                confirmText: 'BLOCK',
                cancelText: 'CANCEL',
                maxWidth: 'sm',
                title: 'CONFIRM BLOCK DAY',
                content: (
                  <SimpleText style={{ padding: 20, fontSize: 16 }} txt="Are you sure you want to block this day?" />
                ),
                onConfirm: () => {
                  const result = dispatch(blockDay(currentUser.uid, found.date));
                  if (result) dispatch(ModalsActions.hideModal('BLOCK_DAY_CONFIRM'));
                  else {
                    dispatch(
                      ModalsActions.showModal('BLOCK_ERROR', {
                        modalType: 'ERROR_ALERT',
                        modalProps: {
                          message: 'Error blocking day, please try again.',
                        },
                      }),
                    );
                  }
                },
              },
            }),
          );
        } else {
          showUnblockDayDialog(start);
        }
      }
      return;
    }
    openCancelledOrdersPopover(false);

    const dayIsBeingClickedOnMonthlyView =
      calendarView === Views.MONTH && moment.utc(start).isSame(moment.utc(end).subtract(1, 'days'));
    const dayHasSomeUnblockedTime = unblockedDays?.find((el) => el.date === moment(start).format('YYYY-MM-DD'));
    if (isWeekend && dayIsBeingClickedOnMonthlyView && !dayHasSomeUnblockedTime) {
      showError('Cannot create an event on a blocked day');
      return;
    }
    if (
      isWeekend &&
      !dayIsBeingClickedOnMonthlyView &&
      isTimeRangeBlocked({ unblockedDays, start, end, timezone: selectedTimezone })
    ) {
      showError('Cannot create an event on a blocked time');
      return;
    }
    const startMom = moment.utc(start).startOf('day');
    if (startMom.isBefore(moment.utc(Date.now()).startOf('day'))) {
      showError('Cannot create an event in the past');
      return;
    }
    const formatedDate = DateUtils.copyLocalDateToTimezone(start, selectedTimezone);
    const endOfDate = end?.getHours() === 23 && end?.getMinutes() === 59;
    showAddEventDialog({
      ...info,
      start: moment(formatedDate),
      end: moment(formatedDate)
        .set({
          h: end?.getHours(),
          m: endOfDate ? 0 : end?.getMinutes(),
        })
        .add(endOfDate ? 1 : 0, 'hour'),
      ...(calendarView === Views.MONTH ? { start: formatedDate.set('h', 7).set('m', 0) } : {}),
    });
  };

  const showError = (msg) => {
    dispatch(
      ModalsActions.showModal('CREATE_EVENT_ERROR', {
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: msg,
        },
      }),
    );
  };

  // Event label in week view
  const WeekEventWrapper = (event) =>
    event?.event?.desc?.subcontracted && calendarType === CALENDAR_TYPE.location ? (
      <SubcontractedEventWrapper event={event} />
    ) : (
      <CustomEventWrapper event={event} materialsInfo={materialsInfo} view={calendarView} />
    );

  const CustomToolbarWrapper = (compProps) => (
    <CustomToolbar
      {...compProps}
      loading={Boolean(loading)}
      eventStartTimes={eventStartTimes}
      showRevenue={showRevenue && (calendarView === Views.MONTH || calendarView === Views.WEEK)}
    />
  );

  // Controls event text label in month view
  const MonthEventWrapper = (event) => {
    const startTimeFormat = moment.utc(event.event.start).minutes() === 0 ? 'ha' : 'h:mma';
    // Checks if the current time is after the end time of the event
    const hasConcluded = moment().tz(selectedTimezone).isAfter(moment(event.event.desc.endTime));
    const background =
      (event.isAllDay && event.event.desc.blockType === TIME_BLOCK_TYPE.OPTIONAL.toUpperCase()) ||
      event.event.desc.blockType !== TIME_BLOCK_TYPE.OPTIONAL.toUpperCase()
        ? EVENT_CALENDAR_COLORS[event.event.desc.eventType]
        : `repeating-linear-gradient(130deg, ${COLORS.CINTAS_WHITE}, ${COLORS.CINTAS_WHITE} 1px, ${COLORS.CINTAS_ORANGE_OPAQUE_RGBA} 1px, ${COLORS.CINTAS_ORANGE_OPAQUE_RGBA} 2px)`;

    if (event.event?.desc?.reason === 'TRAVEL') return <></>;
    return (
      <div>
        <div>
          {event.event?.cancelled ? (
            <div style={{ marginLeft: 4 }}>
              <CancelledEventsHeader
                formName={formName}
                cancelledEventsInfo={event.event?.desc}
                fontSize={12}
                alignment="flex-start"
              />
            </div>
          ) : (
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  background,
                  display: 'inline',
                  float: 'left',
                  width: '10px',
                  minWidth: '10px',
                  height: '10px',
                  margin: 'auto 5px',
                }}
              />
              <span style={{ fontWeight: 500, marginRight: 4 }}>
                {!event.isAllDay && moment(event.event.start).format(startTimeFormat)}
              </span>
              <span
                style={{ textOverflow: 'ellipsis', overflow: 'hidden', textDecoration: hasConcluded && 'line-through' }}
              >
                {event.title}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };

  // Controls events background color
  // monthEventStyle for events in month view, eventStylef for events in day and week view
  // eventStyleGetter params: event, start, end, isSelected
  const eventStyleGetter = (event) => {
    const colorKey = event.desc?.subcontracted ? 'SUBCONTRACTED' : event.desc.eventType;
    const rgbColor = hexToRgb(EVENT_CALENDAR_COLORS[colorKey]);
    const rgbOpacity = event.cancelled ? '0.0' : '0.4';
    const stripesColor = event.desc?.subcontracted
      ? COLORS.CINTAS_MEDIUM_GRAY_OPAQUE_RGBA
      : COLORS.CINTAS_ORANGE_OPAQUE_RGBA;
    const rgbString =
      event.desc.blockType !== TIME_BLOCK_TYPE.OPTIONAL.toUpperCase()
        ? `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, ${rgbOpacity})`
        : `repeating-linear-gradient(130deg, ${COLORS.CINTAS_WHITE}, ${COLORS.CINTAS_WHITE} 5px, ${stripesColor} 5px, ${stripesColor} 7px)`;
    const eventColor = `3px solid ${EVENT_CALENDAR_COLORS[colorKey]}`;
    const eventStyle = {
      background: rgbString,
      borderRadius: '0px',
      opacity: 0.9,
      color: 'black',
      fontWeight: 'bold',
      fontSize: '12px',
      fontFamily: 'proxima-nova, sans-serif',
      borderLeft: eventColor,
      borderBottom: '0px',
      borderRight: '0px',
      borderTop: '0px',
    };

    const monthEventStyle = {
      background: event.allDay ? rgbString : 'transparent',
      borderRadius: '0px',
      opacity: 0.9,
      color: 'black',
      fontWeight: 'bold',
      fontSize: '12px',
      fontFamily: 'proxima-nova, sans-serif',
      border: '0px',
    };

    return {
      className: '',
      style: calendarView === Views.MONTH ? monthEventStyle : eventStyle,
    };
  };

  const slotStyleGetter = (date) => {
    if (calendarType === CALENDAR_TYPE.location && [CALENDAR_VIEWS.MONTH, CALENDAR_VIEWS.YEAR].includes(calendarView)) {
      return {};
    }
    if (!currentUser) return {};
    if (
      isTimeBlocked({ unblockedDays, date, timezone: selectedTimezone }) ||
      date.getHours() < 6 ||
      date.getHours() > 18
    ) {
      return {
        style: {
          background: COLORS.CINTAS_MEDIUM_GRAY_OPAQUE_RGBA,
        },
      };
    }
    return {};
  };

  const dayStyleGetter = (day) => {
    if (!events || events.length === 0) return {};
    const dayPto = getAllDayEventsForDate(day);
    return dayPto.length > 0
      ? {
          style: {
            background:
              dayPto[0].blockType !== TIME_BLOCK_TYPE.OPTIONAL.toUpperCase()
                ? COLORS.CINTAS_ORANGE_OPAQUE_RGBA
                : `repeating-linear-gradient(130deg, ${COLORS.CINTAS_WHITE}, ${COLORS.CINTAS_WHITE} 5px, ${COLORS.CINTAS_ORANGE_OPAQUE_RGBA} 5px, ${COLORS.CINTAS_ORANGE_OPAQUE_RGBA} 7px)`,
          },
        }
      : {};
  };

  const monthDayNumberLabel = ({ date, label, onDrillDown }) => {
    const isToday = date.toDateString() === CURRENT_DATE.toDateString();
    return (
      <button
        type="button"
        style={{
          padding: '5px',
          background: 'none',
          border: 'none',
          cursor: 'pointer',
        }}
        onClick={onDrillDown}
      >
        <span
          style={
            isToday
              ? {
                  background: '#012169',
                  fontWeight: 'bold',
                  color: '#FFFFFF',
                  borderRadius: '50%',
                  padding: '4px',
                  height: 20,
                  width: 20,
                }
              : { color: '#6E6E6E' }
          }
        >
          {label}
        </span>
      </button>
    );
  };

  const rowCell = ({ flex, child, ...rest }) => (
    <RowCell flex={flex} {...rest}>
      {child}
    </RowCell>
  );

  const revenueLabel = (date) =>
    rowCell({
      flex: 7,
      style: { justifyContent: 'flex-start', marginLeft: showingBlockedDayIcon(date) ? 0 : 10 },
      child: <DayRevenueLabel date={moment.tz(date, selectedTimezone).utc().format(DATE_PICKER_FOMAT)} />,
    });

  // Controls calendar month date number label in month view
  const curMonthDateLabel = ({ date, label, onDrillDown }) => (
    <RowLayout
      spacing={0}
      style={{
        justifyContent: showRevenue ? 'space-between' : 'flex-end',
      }}
    >
      {
        showingBlockedDayIcon(date) &&
          rowCell({
            flex: 1,
            child: <></>,
          }) /* This adds padding to the left side to avoid overlap with the blocked-day icon */
      }
      {showRevenue ? revenueLabel(date) : <></>}
      {rowCell({
        flex: 3,
        child: monthDayNumberLabel({
          date: DateUtils.copyLocalDateToTimezone(date, selectedTimezone).toDate(),
          label,
          onDrillDown,
        }),
      })}
    </RowLayout>
  );

  const Label = ({ label, drilldownView, onDrillDown }) => {
    if (!drilldownView) {
      return <span style={{ margin: '0 15px' }}>{label}</span>;
    }
    return <Button onClick={onDrillDown}>{label}</Button>;
  };

  const customWeekHeader = ({ label, drilldownView, onDrillDown }) => {
    const showEarlyMorning = hasEarlyMorningEvents(label, eventStartTimes);
    const showLateNight = hasLateNightEvents(label, eventStartTimes);

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>{showEarlyMorning && <ArrowUpwardIcon style={{ fontSize: 12, marginTop: 2 }} />}</span>
        <Label {...{ label, drilldownView, onDrillDown }} />
        <span>{showLateNight && <ArrowDownwardIcon style={{ fontSize: 12, marginTop: 2 }} />}</span>
      </div>
    );
  };

  const calendar = () => (
    <Paper elevation={0} style={{ height: 'calc(100vh - 242px)' }}>
      <Calendar
        selectable
        events={Immutable.asMutable(transformedEvents)}
        defaultView={defaultView}
        views={{
          day: DayView,
          month: true,
          week: true,
          year: Year,
        }}
        date={selectedDate}
        view={calendarView}
        step={60}
        showMultiDayTimes
        timeslots={1}
        scrollToTime={moment('8:00am', 'h:mma').tz(selectedTimezone)}
        defaultDate={CURRENT_DATE}
        localizer={localizer}
        dayLayoutAlgorithm="no-overlap"
        slotPropGetter={slotStyleGetter}
        dayPropGetter={dayStyleGetter}
        eventPropGetter={eventStyleGetter}
        onView={changViewHandler}
        onSelectEvent={loading ? null : selectEventHandler}
        onNavigate={(date, v) => {
          setSelectedDate(date);
          loadViewportEvents(DateUtils.copyLocalDateToTimezone(date, selectedTimezone), v);
        }}
        onHandleNavigation={navigateHandler}
        disableEditing={disableEditing}
        onEventEdited={onEventEdited}
        onSelectSlot={loading || disableEventCreation ? null : handleCalSlotClick}
        components={{
          dateCellWrapper: (p) => <CalendarCellWrapper {...p} formName={formName} />,
          week: {
            header: customWeekHeader,
            event: WeekEventWrapper,
          },
          month: {
            dateHeader: curMonthDateLabel,
            event: MonthEventWrapper,
          },
          day: {
            event: WeekEventWrapper,
          },
          toolbar: CustomToolbarWrapper,
        }}
      />
      <EventPopper
        openPopper={openPopper}
        anchorEl={anchorEl}
        calendarType={calendarType}
        disableEditing={disableEditing}
        onEventEdited={onEventEdited}
        onClosePopper={popperCloseHandler}
        selectedEvent={selectedEvent}
        onViewDetails={showEventDetailsDialog}
        forceInstructorDisplay={forceInstructorDisplay}
        currentTCI={currentTCI}
      />
    </Paper>
  );

  const instructorsCalendar = () => (
    <RowLayout spacing={0} style={{ justifyContent: 'flex-end' }}>
      <RowCell flex={3}>
        <InstructorsCards loadingData={Boolean(loading)} height={calendarHeight} formName={formName} />
      </RowCell>
      <RowCell flex={9}>{calendar()}</RowCell>
    </RowLayout>
  );

  const optionsToolbar = () => (
    <div style={{ paddingBottom: 12 }}>
      <OptionToolbar
        loading={loading}
        view={calendarView}
        disableEventCreation={disableEventCreation}
        handleButton={() => showAddEventDialog()}
      />
    </div>
  );

  return (
    <Grid container direction="column">
      <RowLayout style={{ justifyContent: 'end' }}>
        {hideOptionsBar ? (
          <></>
        ) : (
          <RowCell item flex={12}>
            {optionsToolbar()}
          </RowCell>
        )}
      </RowLayout>
      <Grid item>{viewsTcisCalendar && !hideOptionsBar ? instructorsCalendar() : calendar()}</Grid>
    </Grid>
  );
};

export default _.flow([
  connect((state) => {
    const calendarType = calendarTypeSelector(state);
    const formName = calendarType === CALENDAR_TYPE.location ? 'LocationsCalendar' : 'CalendarTable';

    return {
      showRevenue: calendarType === CALENDAR_TYPE.location ? false : showRevenueSelector(state),
      utils: state.utils,
      configHolidays: holidaysSelector(state),
      settingsView: getFormValues(formName)(state).view ?? defaultView,
      currentViewport: viewportSelector(state, formName),
      cancelledEventsInfo: cancelledEventsCountSelector(state),
      shouldOpenCancelledOrdersPopover: openCancelledEventsPopoverSelector(state, formName),
      calendarType,
      formName,
    };
  }),
])(CalendarTable);

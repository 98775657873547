import React, { createContext, useContext } from 'react';

const courseSecondaryInstructorContextProps = {
  canHaveSecondaryInstructor: true,
  setCanHaveSecondaryInstructor: () => {},
};

export const CourseSecondaryInstructorContext = createContext(courseSecondaryInstructorContextProps);

export const useCourseSecondaryInstructor = () => {
  const context = useContext(CourseSecondaryInstructorContext);
  if (!context) {
    throw new Error('useCourseSecondaryInstructorContext must be used within a CourseSecondaryInstructorProvider');
  }
  return context;
};

export const CourseSecondaryInstructorProvider = ({ children }) => {
  const [canHaveSecondaryInstructor, setCanHaveSecondaryInstructor] = React.useState(
    courseSecondaryInstructorContextProps,
  );

  return (
    <CourseSecondaryInstructorContext.Provider value={{ canHaveSecondaryInstructor, setCanHaveSecondaryInstructor }}>
      {children}
    </CourseSecondaryInstructorContext.Provider>
  );
};

export const courseSecondaryInstructorConnect = (component) => (
  <CourseSecondaryInstructorProvider>{component}</CourseSecondaryInstructorProvider>
);

/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
import _ from 'lodash';
import React, { useEffect } from 'react';
import { change, Field, initialize } from 'redux-form';
import { LIMITED_COUNTRIES } from '../../../../../../../utils/consts';
import StyledButton from '../../../../../../Button/StyledButton';
import MDAutoComplete from '../../../../../../Forms/FormComponents/MDAutoComplete/MDAutoCompleteField';
import MDTextInputField from '../../../../../../Forms/FormComponents/MDTextInput/MDTextInputField';
import Container from '../../../../../../LayoutBuilders/Container';
import ContainerItem from '../../../../../../LayoutBuilders/ContainerItem';

const filterRows = [0, 1, 2, 3];
export const fieldsNames = {
  name: 'name',
  soldTo: 'sold_to',
  route: 'Route',
  fasRegion: 'fas_region',
  street: 'street',
  city: 'city',
  region: 'region',
  zip: 'postal_code',
  country: 'country_key',
  distributionChannel: 'distribution_channel',
  nationalCustomer: 'national_customer',
  regionalCustomer: 'regional_customer',
  localCustomer: 'Local_Customer',
  salesOffice: 'sales_office',
  higherLevelAccount: 'higher_level_account',
};

export const filtersFields = (extraFields) => [
  ...(extraFields ?? []),
  {
    label: 'Customer Name',
    id: [fieldsNames.name],
    backendField: fieldsNames.name,
    row: 1,
    type: 'text',
  },
  {
    label: 'Sold-to',
    id: [fieldsNames.soldTo],
    backendField: fieldsNames.soldTo,
    row: 1,
    type: 'text',
  },
  {
    label: 'Route',
    id: [fieldsNames.route],
    backendField: fieldsNames.route,
    row: 1,
    type: 'text',
  },
  {
    label: 'Street',
    id: [fieldsNames.street],
    backendField: fieldsNames.street,
    row: 1,
    type: 'text',
  },
  {
    label: 'City',
    id: [fieldsNames.city],
    backendField: fieldsNames.city,
    row: 2,
    type: 'text',
  },
  {
    label: 'State',
    id: [fieldsNames.region],
    backendField: fieldsNames.region,
    row: 2,
    type: 'select',
    options: LIMITED_COUNTRIES.map((c) => c.states ?? [])
      .reduce((r, s) => [...r, ...s], [])
      .map((s) => ({ label: `${s.name} (${s.code})`, id: fieldsNames.region, key: s.code }))
      .sort((a, b) => a.label.localeCompare(b.label)),
  },
  {
    label: 'Postal Code',
    id: [fieldsNames.zip],
    backendField: fieldsNames.zip,
    row: 2,
    type: 'text',
  },
  {
    label: 'Country',
    id: [fieldsNames.country],
    backendField: fieldsNames.country,
    row: -1,
    type: 'select',
    options: LIMITED_COUNTRIES.map((c) => ({ label: c.name, id: fieldsNames.country, key: c.code })),
  },
  {
    label: 'Distribution Channel',
    id: [fieldsNames.distributionChannel],
    backendField: fieldsNames.distributionChannel,
    type: 'text',
    row: -1 /* hides the filter but shows it in the table */,
  },
  {
    label: 'Clear all',
    type: 'clear',
    row: 3,
    hide_column: true,
  },
  {
    label: 'Apply',
    type: 'submit',
    row: 3,
    hide_column: true,
  },
  {
    label: 'Higher Level Account',
    id: [fieldsNames.higherLevelAccount],
    filteringFields: [fieldsNames.nationalCustomer, fieldsNames.regionalCustomer, fieldsNames.localCustomer],
    backendField: fieldsNames.higherLevelAccount,
    row: 2,
    type: 'text',
    hide_column: true,
  },
];

const CustomerSearchFilters = ({
  handleApplyFilters,
  extraFields,
  dispatch,
  formName,
  getValue,
  getValueLabel,
  hideClearAll,
}) => {
  const [currentFilters, setCurrentFilters] = React.useState([]);

  useEffect(() => {
    const initialValues = filtersFields(extraFields).filter((fl) => Boolean(fl.initialValue));
    if (_.isEmpty(initialValues)) return;
    dispatch(
      initialize(formName, {
        filters: initialValues.reduce((prev, curr) => ({ ...prev, [curr.id]: curr.initialValue }), {}),
      }),
    );
    enableDisableApplyBtn(true);
  }, []);

  useEffect(() => {
    setCurrentFilters(
      filtersFields(extraFields).map((field) => ({
        ...field,
        applied: field.type === 'submit',
        fromField: field.id ? `filters.${field.id}` : undefined,
      })),
    );
  }, [extraFields]);

  const getHeaders = () => currentFilters.filter((f) => !f.hide_column);

  const apply = (targetId, targetValue) => {
    const updated = [
      ...currentFilters.map((f) => ({
        ...f,
        applied: f.type === 'submit' ? true : targetId === f.fromField ? targetValue : getValueLabel(f),
      })),
    ];
    setCurrentFilters(updated);
    handleApplyFilters(updated);
  };

  const getFilterField = (filter) => {
    switch (filter.type) {
      case 'text':
        return textField({ id: filter.fromField, label: filter.label });
      case 'select':
        return selectField({
          id: filter.fromField,
          label: filter.label,
          options: filter.options ?? [],
          disableClear: filter.disableClear,
          disable: filter.disableInput,
        });
      case 'button':
        return btn({
          label: filter.label,
          onTap: filter.onTap,
          style: { ...(filter.style ?? {}), flex: filter.flex ?? 1 },
          disable: filter.disabled,
        });
      case 'submit':
        return btn({
          label: filter.label,
          style: { width: '48%', marginLeft: '52%' },
          onTap: apply,
          disable: filter.applied,
        });
      case 'clear':
        return hideClearAll ? <></> : clearFiltersBtn();
      default:
        return null;
    }
  };

  const handleKeyPress = async (event, formPath) => {
    if (event.keyCode === 13) {
      event.target?.blur();
      apply(formPath, event.target?.value);
    }
  };

  const handleClearFilters = async () => {
    const fields = getHeaders();
    for (let i = 0; i < fields.length; i++) {
      await dispatch(change(formName, fields[i].fromField, undefined));
    }
    handleFieldChange();
  };

  const enableDisableApplyBtn = (enable) => {
    const btnIdx = currentFilters.findIndex((f) => f.type === 'submit');
    if (btnIdx === -1) return;
    if (currentFilters[btnIdx].applied === !enable) return;
    const updated = [...currentFilters.map((f) => ({ ...f }))];
    if (updated[btnIdx].applied !== !enable) {
      updated[btnIdx] = {
        ...updated[btnIdx],
        applied: !enable,
      };
      setCurrentFilters([...updated]);
    }
  };

  const handleFieldChange = () => {
    enableDisableApplyBtn(true);
  };

  const textField = ({ id, label }) => (
    <Field
      id={id}
      name={id}
      component={MDTextInputField}
      type="text"
      size="small"
      variant="outlined"
      placeholder={label}
      onHandleChange={handleFieldChange}
      onKeyDown={(event) => handleKeyPress(event, id)}
      label={label}
      noBorderRadius
    />
  );

  const selectField = ({ id, label, options, disableClear, disable }) => (
    <Field
      options={options}
      id={id}
      name={id}
      component={MDAutoComplete}
      onChange={handleFieldChange}
      onKeyDown={handleKeyPress}
      disableClearable={disableClear}
      disabled={disable}
      type="text"
      size="small"
      variant="outlined"
      placeholder={label}
      label={label}
      getLabel={(option) => option.label ?? ''}
      noBorderRadius
    />
  );

  const btn = ({ label, disable, onTap, ...rest }) => (
    <StyledButton
      style={{ width: '100%' }}
      variant="contained"
      color="primary"
      disabled={disable}
      buttonContent={label}
      handleButton={onTap}
      {...(rest ?? {})}
    />
  );

  const clearFiltersBtn = () => (
    <StyledButton
      style={{ padding: 0, margin: 'auto', marginTop: 6 }}
      variant="text"
      color="primary"
      disabled={getHeaders().filter((f) => getValue(f)).length === 0}
      buttonContent="Clear All"
      handleButton={handleClearFilters}
    />
  );

  const filterRowsNumbers = filterRows.map((rowNum) => currentFilters.filter((f) => f.row === rowNum));
  const highestRowIntervals = [...filterRowsNumbers].sort((a, b) => (a.length > b.length ? -1 : 1))[0].length;

  return (
    <Container spacing={2}>
      {filterRowsNumbers.map((filtersList, i) => {
        const stl = { paddingTop: 0, paddingBottom: 0 };
        const flex = Math.floor(12 / highestRowIntervals);
        const paddings = [];
        const inputs = filtersList.map((filter, j, arr) => (
          <ContainerItem style={{ ...stl, ...(filter.style ?? {}) }} flex={filter.flex ?? Math.floor(12 / arr.length)}>
            {getFilterField(filter)}
          </ContainerItem>
        ));
        if (i !== filterRows.length - 1) {
          while (inputs.length + paddings.length < highestRowIntervals) {
            paddings.push(<ContainerItem style={stl} flex={flex} />);
          }
        }
        return [...inputs, ...paddings];
      })}
    </Container>
  );
};

export default CustomerSearchFilters;

/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import { Divider, IconButton } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import _ from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Field, change, unregisterField } from 'redux-form';

import { BOOKING_MODE, COLORS, MATERIAL_STATUS, MATERIAL_TYPE, ROLE_ACCESSES } from '../../../../../utils/consts';
import { calcCLevels, removeLeadingZeros, showTwoDecimal } from '../../../../../utils/helpers';
import StyledButton from '../../../../Button/StyledButton';
import FASFieldTitle from '../../../../Forms/CustomFormComponents/FASFieldTitle';
import FASTimePickerField from '../../../../Forms/CustomFormComponents/FASTimePickerField/FASTimePickerField';
import FASTimeText from '../../../../Forms/CustomFormComponents/FASTimeText';
import MDAutoComplete from '../../../../Forms/FormComponents/MDAutoComplete/MDAutoCompleteField';
import MDSlider from '../../../../Forms/FormComponents/MDSlider/MDSlider';
import MDTextInputField from '../../../../Forms/FormComponents/MDTextInput/MDTextInputField';
import Container from '../../../../LayoutBuilders/Container';
import ContainerItem from '../../../../LayoutBuilders/ContainerItem';
import CoursePrice from './CoursePrice';
import SkillCheckBooking from './SkillCheckBooking';

import * as ModalsActions from '../../../../../redux/actions/modals.actions';
import * as PricingActions from '../../../../../redux/actions/pricing.actions';
import { materialsDataSelector } from '../../../../../redux/selectors/materials.selector';
import { listingsSelector } from '../../../../../redux/selectors/sap.selectors';
import { hasUserAccessSelector } from '../../../../../redux/selectors/user.selectors';
import { timezoneSelector } from '../../../../../redux/selectors/utils.selectors';
import { calcMaxAdditionalParticipantsNew, calcMaxParticipants, priceToLevel } from '../../../../../utils/event.utils';
import { useCourseSecondaryInstructor } from './CourseSecondaryInstructor';

export const getPricing = (pricing, customerDistributionChannel, genericQuote) => {
  if (genericQuote) {
    return parseFloat((pricing?.Location_Price ?? '').trim());
  }
  switch (customerDistributionChannel) {
    case '10':
      return parseFloat((pricing?.Location_Price ?? '').trim());
    case '11':
      return parseFloat((pricing?.Regional_Price ?? '').trim());
    case '12':
      return parseFloat((pricing?.National_Price ?? '').trim());
    case '90':
      return parseFloat((pricing?.Inter_Company_Price ?? '').trim());
    default:
      return parseFloat((pricing?.Location_Price ?? '').trim());
  }
};

const formName = 'AddEventDialog';
const WARNING_TRIGGER = {
  course_change: 'crs_chg',
  participants_change: 'prt_chg',
};

const WARNING_TARGET = {
  [WARNING_TRIGGER.course_change]: 'courses that involve skills checks',
  [WARNING_TRIGGER.participants_change]: 'skill check participants',
};

const CourseSelect = (props) => {
  const { member, i, fields, dispatch, mode, selectedLocation, genericQuote } = props;
  const { setCanHaveSecondaryInstructor } = useCourseSecondaryInstructor();
  const [pricingError, setPricingError] = useState();
  const [skillcheckPricingError, setSkillcheckPricingError] = useState();

  const { customer, courses } = useSelector((state) => state.form[formName].values);
  const materialsInfo = useSelector(materialsDataSelector);
  const listingsData = useSelector(listingsSelector);
  const commissionPercentages = useSelector((state) => state.cl.data.levels);
  const formValues = useSelector((state) => state.form[formName].values);
  // const formInitialValues = useSelector((state) => state.form[formName].initial);
  const hasSecondaryInstructor = !_.isEmpty(formValues?.secondaryTci ?? '');
  const getAdditionalParticipantsMaterialInfo = (course) => {
    if (!course) return {};
    const materialAdditionalParticipantsID = course?.additionalParticipants?.id ?? undefined;
    const materialAdditionalPart =
      materialAdditionalParticipantsID &&
      (materialsInfo[MATERIAL_TYPE.ADDITIONAL_PARTICIPANTS][materialAdditionalParticipantsID] ?? {});
    const additionalParticipantsUnv = !materialAdditionalPart
      ? true
      : !((_.find(listingsData, { Material: materialAdditionalPart.code }) ?? {}).inlcuded ?? true);
    return {
      materialAdditionalParticipantsID,
      materialAdditionalPart,
      additionalParticipantsUnavailable: additionalParticipantsUnv,
    };
  };
  const { additionalParticipantsUnavailable } = getAdditionalParticipantsMaterialInfo(
    formValues?.courses && i < formValues.courses.length && formValues.courses[i].course,
  );
  const coursesData = _(Object.values(materialsInfo[MATERIAL_TYPE.COURSE]))
    .filter(
      (m) =>
        (m.status === MATERIAL_STATUS.VISIBLE || m.status === MATERIAL_STATUS.PARTNERS) &&
        m.salesOrg === customer.sales_organization,
    )
    .map((m) => {
      const listing = _.find(listingsData, { Material: m.code, Sales_Organization: m.salesOrg });
      return { ...m, included: (listing && listing.inlcuded) || false };
    })
    .value();
  const sortedCoursesData = coursesData.sort((a, b) => {
    const aTitle = a.title.toUpperCase();
    const bTitle = b.title.toUpperCase();
    if (aTitle < bTitle) {
      return -1;
    }
    if (aTitle > bTitle) {
      return 1;
    }
    return 0;
  });
  const [disabledCustomerPrice, setDisabledCustomerPrice] = React.useState(false);
  const [editSTime, setEditSTime] = React.useState(false);
  const [eventStart, setEventStart] = React.useState(moment.utc(formValues.startTime));
  const showTashIcon = (courses ?? []).length > 1;
  const [warningShowed, setWarningShowed] = React.useState(
    Object.values(WARNING_TRIGGER).reduce((red, trig) => ({ ...red, [trig]: false }), {}),
  );
  const [allowAddPart, setAllowAddPart] = React.useState(false);
  const hasCreateAccess = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.bookEvent]));

  React.useEffect(async () => {
    if (
      formValues.courses &&
      formValues.courses[i] &&
      !formValues.courses[i].marketPrice &&
      formValues.courses[i].course
    ) {
      await fetchPricing(
        formValues.courses[i].course,
        {
          skillcheckObject: formValues.courses[i].skillcheck,
          skillcheckParticipants: formValues.courses[i].skillcheckParticipants,
          participants: formValues.courses[i].participants,
          price: showTwoDecimal(formValues.courses[i].price),
          skillcheckPrice: formValues?.courses[i].skillcheckPrice,
          selectedAddons: formValues.courses[i].selectedAddons,
        },
        false,
      );
    }
  }, []);

  const changedFollowingCoursesTime = (courseIndex, curCourseEndTime) => {
    if (!formValues.courses || !formValues.courses.length) {
      return;
    }
    let followedEndTime = moment.utc(curCourseEndTime);
    formValues.courses.forEach((OScourse, index) => {
      if (courseIndex < index && OScourse.course) {
        const courseStartTime = followedEndTime.clone();
        followedEndTime = followedEndTime.clone().add(OScourse.course?.duration, 'minutes').utc();
        dispatch(change(formName, `courses[${index}].courseStartTime`, courseStartTime));
        // uncommented these lines to handle changing participants of skillcheck in preceding courses
        const courseEndTime = followedEndTime.clone();
        dispatch(change(formName, `courses[${index}].courseEndTime`, courseEndTime));
      }
    });
  };

  const changeFollowingCoursesDate = (courseIndex, newDate) => {
    if (!formValues.courses || !formValues.courses.length || !newDate) {
      return;
    }

    let dateMoment = newDate;
    formValues.courses.forEach((OScourse, index) => {
      if (courseIndex < index && OScourse.course && OScourse.courseStartTime) {
        // dateMoment.add('hours', OScourse.courseStartTime?.hour() - newDate.hour());
        const newStart = dateMoment.clone();
        // const newStart = moment.utc({
        //   year: dateMoment.year(),
        //   month: dateMoment.month(),
        //   day: dateMoment.date(),
        //   hour: OScourse.courseStartTime?.hour(),
        //   minute: OScourse.courseStartTime?.minute(),
        // });
        const prevStartTime = OScourse.courseStartTime;
        dispatch(change(formName, `courses[${index}].courseStartTime`, newStart));
        if (OScourse.courseEndTime) {
          const prevEndTime = OScourse.courseEndTime;
          const durationMs = moment.utc(prevEndTime).toDate() - moment.utc(prevStartTime).toDate();
          const newEnd = newStart.clone().add(durationMs, 'ms');
          dispatch(change(formName, `courses[${index}].courseEndTime`, newEnd));
          dateMoment = newEnd;
        }
      }
    });
  };

  const calcEventEndTime = (courseObj, courseStartTime, skillcheckObject) => {
    const skillcheckParticipants = formValues?.courses[i]?.skillcheckFull
      ? formValues?.courses[i]?.participants_count
      : formValues?.courses[i]?.skillcheckParticipants;
    const skillcheckPartNotNull = skillcheckParticipants ?? calcMaxParticipants(courseObj, false);
    const skillcheckUnitDuration = skillcheckObject?.duration ?? 0;
    const skillcheckTotalDuration = skillcheckPartNotNull * skillcheckUnitDuration;
    const courseEndTime = courseStartTime.clone().add(courseObj.duration + skillcheckTotalDuration, 'minutes');
    return courseEndTime;
  };

  const getSkillcheckPricing = async (skillcheckSAPCode, initialPrice, skillcheckPricing) => {
    try {
      const marketPricing = _.find(skillcheckPricing, { Customer: '-1' });
      const customerPricing = _.find(skillcheckPricing, { Customer: customer.sold_to });
      if (!marketPricing) {
        throw Error();
      }
      const marketPrice = getPricing(marketPricing, customer.distribution_channel, genericQuote);
      const customerPrice = !customerPricing
        ? marketPrice
        : getPricing(customerPricing, customer.distribution_channel, genericQuote);
      const levels = calcCLevels(commissionPercentages, Number(marketPrice ?? 0));
      const priceLevel = priceToLevel(Number(customerPrice ?? 0), levels);
      const skillcheckPrice = {
        marketPrice,
        customerPrice: initialPrice ? showTwoDecimal(initialPrice) : customerPrice,
        disableCustomerPrice: Boolean(customerPricing),
        priceLevel,
      };
      setSkillcheckPricingError();
      return skillcheckPrice;
    } catch (e) {
      setSkillcheckPricingError({
        soldto: customer.sold_to,
        salesOffice: customer.sales_office,
        salesOrg: customer.sales_organization,
        material: skillcheckSAPCode,
      });
      return null;
    }
  };

  const adjustSkillcheckForm = async (value, preloadedFields) => {
    const skillcheckObject =
      (preloadedFields ?? {}).skillcheckObject ?? materialsInfo[MATERIAL_TYPE.SKILL_CHECK][value.skillcheckID] ?? null;
    let skillcheckSAPCode = '';
    let stateUpdates = {};
    if (skillcheckObject && value.skillcheckID) {
      skillcheckSAPCode = skillcheckObject.code;
      const notAvailable = !_.find(listingsData ?? [], { Material: skillcheckSAPCode }).inlcuded;
      stateUpdates = {
        skillcheck: { ...skillcheckObject, notAvailable },
        skillcheckParticipants: notAvailable
          ? 0
          : (preloadedFields ?? {}).skillcheckParticipants ?? calcMaxParticipants(value, false),
        skillcheckFull: false,
      };
    } else {
      stateUpdates = {
        skillcheck: false,
        skillcheckParticipants: false,
        skillcheckFull: undefined,
      };
      await Promise.all([
        await dispatch(unregisterField(formName, `courses[${i}].skillcheck`)),
        await dispatch(unregisterField(formName, `courses[${i}].skillcheckFull`)),
        await dispatch(unregisterField(formName, `courses[${i}].skillcheckParticipants`)),
      ]);
    }
    return { skillcheckSAPCode, skillcheckObject, skillcheckStateUpdates: stateUpdates };
  };

  const fetchPricing = async (value, preloadedFields, updateFollowingCourse) => {
    const sapMaterialId = value.code;
    const { skillcheckSAPCode, skillcheckObject, skillcheckStateUpdates } = await adjustSkillcheckForm(
      value,
      preloadedFields,
    );

    try {
      const allPricing = await dispatch(
        PricingActions.fetchPricing(
          customer,
          skillcheckSAPCode ? `${sapMaterialId},${skillcheckSAPCode}` : sapMaterialId,
          false,
          false,
          selectedLocation?.id,
        ),
      );

      const pricing = skillcheckSAPCode
        ? _.flatten((allPricing ?? []).filter((p) => p[0]?.Material === sapMaterialId))
        : allPricing;
      const skillPricing = skillcheckSAPCode
        ? _.flatten((allPricing ?? []).filter((p) => p[0].Material === skillcheckSAPCode))
        : [];
      let skillcheckPrice;
      if (skillcheckSAPCode) {
        skillcheckPrice = await getSkillcheckPricing(
          skillcheckSAPCode,
          (preloadedFields ?? {}).skillcheckPrice,
          skillPricing,
        );
        skillcheckStateUpdates.skillcheckPrice = skillcheckPrice;
      } else {
        setSkillcheckPricingError();
      }

      const marketPricing = _.find(pricing, { Customer: '-1' });
      // eslint-disable-next-line max-len
      const customerPricing = _.find(pricing, { Customer: customer.sold_to });
      const isAdditionalParticipantsUnavailable =
        getAdditionalParticipantsMaterialInfo(value)?.additionalParticipantsUnavailable ?? false;
      const market = getPricing(marketPricing, customer.distribution_channel, genericQuote);
      const commissionLevels = calcCLevels(commissionPercentages, market);
      setDisabledCustomerPrice(Boolean(customerPricing));
      const newValues = {
        course: value ?? {},
        marketPrice: market,
        customerPrice: preloadedFields?.price
          ? showTwoDecimal(preloadedFields?.price)
          : showTwoDecimal(
              customerPricing ? getPricing(customerPricing, customer.distribution_channel) : market,
              genericQuote,
            ),
        currency: (customerPricing ?? marketPricing).Curr,
        disableCustomerPrice: Boolean(customerPricing),
        commissionLevels,
        commissionPercentages,
        selectedAddons: preloadedFields?.selectedAddons ?? {},
        priceLevel: priceToLevel(market, commissionLevels),
        participants: preloadedFields?.participants ?? 1,
        additional_participants: !isAdditionalParticipantsUnavailable
          ? [
              {
                id: value?.additionalParticipants?.id ?? '',
                qty:
                  (formValues.courses[i].additional_participants?.[0]?.qty ?? 0) + (preloadedFields?.participants ?? 0),
              },
            ]
          : [],
        ...skillcheckStateUpdates,
      };
      // change course start time
      if (value) {
        let courseStartTime;

        if (i === 0) {
          courseStartTime = moment.utc(formValues.startTime);
        } else {
          courseStartTime = formValues.courses[i].startTime
            ? moment.utc(formValues.courses[i].startTime)
            : moment.utc(formValues.courses[i - 1].courseEndTime);
        }
        const courseEndTime = calcEventEndTime(value, courseStartTime, skillcheckObject);
        newValues.courseStartTime = courseStartTime;
        newValues.courseEndTime = courseEndTime;
      }
      setPricingError();
      await dispatch(change(formName, `courses[${i}]`, { ...((formValues ?? {}).courses[i] ?? {}), ...newValues }));
      if (value && updateFollowingCourse) changedFollowingCoursesTime(i, newValues.courseEndTime);
      return;
    } catch (error) {
      dispatch(change(formName, `courses[${i}].marketPrice`, ''));
      dispatch(change(formName, `courses[${i}].customerPrice`, ''));
      dispatch(change(formName, `courses[${i}].skillcheckPrice`, {}));
      setPricingError({
        soldto: customer.sold_to,
        salesOffice: customer.sales_office,
        salesOrg: customer.sales_organization,
        material: sapMaterialId,
      });
      dispatch(
        ModalsActions.showModal('FETCH_PRICING_ERROR_MODAL', {
          modalType: 'ERROR_ALERT',
          modalProps: { message: 'Something happened while fetching pricing. Please, try again!' },
        }),
      );
    }
  };

  const changeStartTimeHandler = (courseIndex) => {
    if (editSTime) {
      if (formValues.courses[courseIndex].courseStartTime) {
        const skillcheckObj = formValues.courses[courseIndex].skillcheck;
        const curCourseEndTime = calcEventEndTime(
          formValues.courses[courseIndex].course,
          formValues.courses[courseIndex].courseStartTime.clone(),
          skillcheckObj,
        );
        dispatch(change(formName, `courses[${courseIndex}].courseEndTime`, curCourseEndTime));
        changedFollowingCoursesTime(courseIndex, curCourseEndTime);
      }
    }
    setEditSTime(!editSTime);
  };

  const changeSkillCheck = (participants) => {
    if (formValues?.courses[i]?.skillcheck?.notAvailable) return;
    if (![BOOKING_MODE.booking, BOOKING_MODE.quote].includes(mode)) {
      checkForTimeCalcWarning(WARNING_TRIGGER.participants_change, { currCourse: formValues?.courses[i]?.courseObj });
    }
    dispatch(change(formName, `${member}.skillcheckParticipants`, participants));
    const skillCheckID = formValues?.courses[i]?.course?.skillcheckID;
    const skillCheckObject = materialsInfo[MATERIAL_TYPE.SKILL_CHECK][skillCheckID];

    const skillCheckDuration = skillCheckObject?.duration;
    const courseDuration = formValues?.courses[i]?.course?.duration;
    if (formValues?.courses[i]?.courseStartTime && Number(participants) >= 0) {
      const totalDuration = participants * skillCheckDuration + courseDuration;
      const courseEndTime = formValues?.courses[i]?.courseStartTime.clone().add(totalDuration, 'minutes').utc();
      dispatch(change(formName, `courses[${i}].courseEndTime`, courseEndTime));
      changedFollowingCoursesTime(i, courseEndTime);
    }
  };

  const changeAddons = (participants) => {
    formValues.courses[i].addOns.forEach((__, idx) => {
      dispatch(change(formName, `courses[${i}].addons[${idx}].qty`, participants));
    });
  };

  const handleChangeParticipants = (value) => {
    let participants = parseInt(value, 10);
    if (
      formValues.courses[i].course?.additionalParticipants?.id &&
      value === calcMaxParticipants(courses[i].course, hasSecondaryInstructor)
    ) {
      setAllowAddPart(true);
      participants += parseInt(formValues?.courses[i]?.additional_participants?.[0]?.qty ?? 0, 10);
    } else if (formValues.courses[i].course?.additionalParticipants?.id) {
      dispatch(change(formName, `courses[${i}].additional_participants[0].qty`, 0));
      setAllowAddPart(false);
    }
    if (hasSkillCheck) {
      changeSkillCheck(participants);
    }
    if (formValues.courses[i].addOns && participants >= 0) {
      changeAddons(participants);
    }
  };

  const handleChangeAdditionalParticipants = (value) => {
    const participants = parseInt(value, 10) + parseInt(formValues?.courses[i]?.participants, 10);
    if (hasSkillCheck) {
      changeSkillCheck(participants);
    }
    if (formValues.courses[i].addOns) {
      changeAddons(participants);
    }
  };

  const showWarningMessage = (trigger) => {
    if (warningShowed[trigger]) {
      return;
    }

    dispatch(
      ModalsActions.showModal('WARNING_ALERT', {
        modalType: 'WARNING_ALERT',
        modalProps: {
          duration: 8000,
          message: `Changing ${WARNING_TARGET[trigger]} will recalculate times for whole day.`,
        },
      }),
    );

    setWarningShowed({
      ...(warningShowed ?? {}),
      [trigger]: true,
    });
  };

  const checkForTimeCalcWarning = (trigger, { oldVal, newVal, currCourse }) => {
    switch (trigger) {
      case WARNING_TRIGGER.course_change:
        if (!oldVal || !newVal) return;
        if (oldVal.skillcheckID) break;
        if (newVal.skillcheckID) break;
        return;
      case WARNING_TRIGGER.participants_change:
        if (!currCourse) return;
        if (currCourse.skillcheckID) break;
        return;
      default:
        return;
    }

    showWarningMessage(trigger);
  };

  // Ensures first course starts at startTime selected
  React.useEffect(() => {
    if (
      i === 0 &&
      formValues &&
      formValues.courses &&
      formValues.courses.length &&
      formValues.courses[0].course &&
      formValues.startTime &&
      eventStart !== formValues.startTime
    ) {
      const firstCourse = formValues.courses[0];
      /* const firstStart = firstCourse.courseStartTime ? moment.utc(firstCourse.courseStartTime) : null;
      const newStart = formValues.startTime ? moment.utc(formValues.startTime) : null;
      const firstStartFormatted = firstStart.format(DATE_PICKER_FOMAT);
      const newStartFormatted = newStart.format(DATE_PICKER_FOMAT);
      const isDateUpdate = firstCourse.courseStartTime ? (firstStartFormatted !== newStartFormatted) : false; */
      dispatch(change(formName, 'courses[0].courseStartTime', moment.utc(formValues.startTime)));
      const { skillcheck } = firstCourse;
      let courseEndTime = moment
        .utc(formValues.startTime)
        .clone()
        .add(formValues.courses[0].course.duration, 'minutes');
      if (skillcheck) {
        const skillcheckDuration =
          Number(skillcheck.duration ?? 0) *
          Number(firstCourse.skillcheckParticipants ?? calcMaxParticipants(skillcheck, hasSecondaryInstructor, 0));
        courseEndTime = courseEndTime.clone().add(skillcheckDuration, 'minutes');
      }
      dispatch(change(formName, 'courses[0].courseEndTime', courseEndTime));
      if ([BOOKING_MODE.booking, BOOKING_MODE.quote].includes(mode)) {
        changedFollowingCoursesTime(0, courseEndTime);
      } else {
        changeFollowingCoursesDate(0, courseEndTime);
      }
      setEventStart(formValues.startTime);
    }
  }, [formValues.startTime]);

  const calcCourseTotalDuration = (course) => {
    let totalDuration = course?.course?.duration ?? 0;
    // if skillcheck calculates total duration based on participants and skillcheck duration
    if (course?.skillcheck) {
      totalDuration = course.skillcheckFull
        ? course.course?.duration + course.participants_count * course.skillcheck?.duration
        : course.course?.duration + course.skillcheck?.duration * course.skillcheckParticipants;
    }
    return totalDuration;
  };

  // Used to update course end time when start time changes
  React.useEffect(() => {
    if (formValues?.courses[i]?.courseStartTime) {
      const course = formValues.courses[i];
      const totalDuration = calcCourseTotalDuration(course);
      // changed due to FAS-2884 and FAS-2451
      // const initialTotalDuration = calcCourseTotalDuration(formInitialValues?.courses?.[i]);
      // const courseInitialEndTime = moment
      //   .utc(formInitialValues?.courses?.[i]?.courseStartTime)
      //   .clone()
      //   .add(initialTotalDuration, 'minutes');
      const courseEndTime = moment.utc(formValues?.courses[i]?.courseStartTime).clone().add(totalDuration, 'minutes');
      dispatch(change(formName, `courses[${i}].courseEndTime`, courseEndTime));
      // changed due to FAS-2884 and FAS-2451
      // check if end time changes to change booking mode to rescheduling
      // if (mode === BOOKING_MODE.editing && !courseEndTime.isSame(courseInitialEndTime) && !formValues.quoteID) {
      //   dispatch(change(formName, 'bookingMode', BOOKING_MODE.rescheduling));
      // }
      if ([BOOKING_MODE.booking, BOOKING_MODE.quote].includes(mode)) {
        changedFollowingCoursesTime(i, courseEndTime);
      }
    }
  }, [formValues.courses[i].courseStartTime]);

  React.useEffect(() => {
    if (i === 0 && formValues.startTime && formValues.courses[0].course) {
      dispatch(change(formName, 'startTime', moment.utc(formValues.courses[0].courseStartTime)));
    }
  }, [formValues.courses.length]);

  React.useEffect(() => {
    if (formValues.courses[i].course?.additionalParticipants?.id) {
      dispatch(
        change(
          formName,
          `courses[${i}].additional_participants[0].id`,
          formValues.courses[i].course.additionalParticipants.id,
        ),
      );
      dispatch(
        change(
          formName,
          `courses[${i}].additional_participants[0].qty`,
          formValues.courses[i].additional_participants?.[0]?.qty || 0,
        ),
      );
      setCanHaveSecondaryInstructor(true);
    } else {
      setCanHaveSecondaryInstructor(false);
      dispatch(change(formName, `secondaryTci`, ''));
    }
  }, [formValues.courses[i].course?.additionalParticipants]);

  React.useEffect(() => {
    if (!formValues.courses[i].course?.additionalParticipants?.id) return;
    const participants = formValues.courses[i]?.course?.participants?.max || 0;
    if (formValues.secondaryTci) {
      if (formValues.courses[i].additional_participants?.[0]?.qty === participants) return;
      dispatch(
        change(
          formName,
          `courses[${i}].additional_participants[0].qty`,
          formValues.courses[i].additional_participants?.[0]?.qty + participants,
        ),
      );
    } else if (formValues.courses[i].additional_participants?.[0]?.qty >= participants) {
      dispatch(
        change(
          formName,
          `courses[${i}].additional_participants[0].qty`,
          formValues.courses[i].additional_participants?.[0]?.qty - participants,
        ),
      );
    }
  }, [formValues.secondaryTci]);

  React.useEffect(() => {
    if (formValues.courses[i].participants === calcMaxParticipants(formValues.courses[i].course, false)) {
      setAllowAddPart(true);
    }
  }, [formValues.courses[i]?.participants]);

  const resetParticipantsCount = (newFormValues) => {
    if (newFormValues.course) {
      handleChangeParticipants(calcMaxParticipants(newFormValues.course, hasSecondaryInstructor));
    }
  };

  const showStartEndTime = formValues.courses.length > 0 && formValues.date && formValues.startTime;
  const hasEndTime = formValues.courses && formValues.courses[i].courseEndTime;
  const hasSkillCheck = formValues?.courses[i]?.course?.skillcheckID;
  const isEditableTime = i > 0 && formValues.courses && formValues.courses[i].courseEndTime;

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;

  const title = (txt, rest) => <FASFieldTitle {...(rest ?? {})} title={txt} />;
  const trashBtn = () => (
    <IconButton
      type="button"
      aria-label="delete"
      disabled={!hasCreateAccess}
      onClick={() => {
        fields.remove(i);
      }}
    >
      <DeleteIcon fontSize="small" />
    </IconButton>
  );

  const startTimeInput = () => {
    const focusDate =
      moment.utc(formValues.courses[i - 1].courseEndTime) || moment.utc(formValues.courses[i].courseStartTime);
    return (
      <Field
        id={`${member}.courseStartTime`}
        name={`${member}.courseStartTime`}
        initialFocusedDate={focusDate} // focused date should point to the previous course end time or it's actual start time
        component={FASTimePickerField}
        required
        noBorderRadius
        normalize={(v) => v?.utc()}
        onChange={() => {
          // if (mode === BOOKING_MODE.editing) {
          //   dispatch(change(formName, 'bookingMode', BOOKING_MODE.rescheduling));
          // }
        }}
      />
    );
  };

  const startTimeLabel = () => (
    <Field name={`${member}.courseStartTime`} id="courseStartTime" component={FASTimeText} />
  );
  const endTimeLabel = () => <Field name={`${member}.courseEndTime`} id="courseEndTime" component={FASTimeText} />;

  const editBtn = () => (
    <StyledButton
      variant={editSTime ? 'contained' : 'outlined'}
      width={editSTime ? '100px' : '20px'}
      height={editSTime ? null : '20px'}
      handleButton={() => changeStartTimeHandler(i)}
      buttonContent={editSTime ? 'Confirm' : <EditIcon style={{ fontSize: 18 }} />}
    />
  );

  const courseInput = () => (
    <Field
      disabled={!hasCreateAccess}
      options={sortedCoursesData}
      getLabel={(option) => `${removeLeadingZeros(option.code)} - ${option.title} `}
      getValue={(option) => option.id}
      variant="outlined"
      placeholder="Select course"
      id="course"
      name={`${member}.course`}
      required
      getOptionDisabled={(o) => !o.included}
      component={MDAutoComplete}
      onValueChange={async (value) => {
        if (![BOOKING_MODE.booking, BOOKING_MODE.quote].includes(mode)) {
          checkForTimeCalcWarning(WARNING_TRIGGER.course_change, {
            oldVal: formValues?.courses[i]?.courseObj,
            newVal: value,
          });
          await dispatch(change(formName, `${member}.courseObj`, value));
        }
        fetchPricing(value);
        resetParticipantsCount({ ...formValues?.courses[i], courseObj: value });
      }}
      size="small"
      label="Course"
      popupIcon={<KeyboardArrowDownIcon color="primary" />}
      disableClearable
      noBorderRadius
      renderOption={(o) => (
        <span
          {...o}
          style={{
            color: !o.included && COLORS.CINTAS_RED,
            fontStyle: !o.included && 'italic',
            fontSize: 15,
            '& > span': {
              marginRight: 10,
              fontSize: 18,
            },
          }}
        >
          {`${removeLeadingZeros(o.code)} - ${o.title} `}
        </span>
      )}
    />
  );

  const divider = () => <Divider style={{ margin: '2px 0px' }} />;

  const coursePriceComponent = (forSkillcheck) => (
    <CoursePrice
      key={forSkillcheck ? `SkillPrice${member}${i}` : `CoursePrice${member}${i}`}
      mode={mode}
      canEdit
      isSkillcheck={forSkillcheck}
      member={forSkillcheck ? `${member}.skillcheckPrice` : member}
      i={i}
      disabledCustomerPrice={disabledCustomerPrice}
      pricingError={pricingError}
    />
  );

  const courseSkillCheck = () => (
    <SkillCheckBooking
      mode={mode}
      member={member}
      listings={listingsData}
      pricingBuilder={() => pricingComponent(true)}
      changedFollowingCoursesTime={(idx, courseEndTime) => {
        changedFollowingCoursesTime(idx, courseEndTime);
        if (![BOOKING_MODE.booking, BOOKING_MODE.quote].includes(mode)) {
          checkForTimeCalcWarning(WARNING_TRIGGER.participants_change, {
            currCourse: formValues?.courses[i]?.courseObj,
          });
        }
      }}
      i={i}
    />
  );

  const participantSlider = () => (
    <Field
      variant="outlined"
      placeholder="0"
      id="courseParticipantsSlider"
      name={`${member}.participants`}
      component={MDSlider}
      label="Participants"
      onChange={handleChangeParticipants}
      disabled={(!courses[i]?.course ?? false) || !hasCreateAccess}
      max={calcMaxParticipants(courses[i]?.course, false, 1)}
      min={courses[i]?.course && 1}
    />
  );

  const participantsInput = () => (
    <Field
      variant="outlined"
      placeholder="Participants"
      id={`courseParticipants${i}${member}`}
      name={`${member}.participants`}
      component={MDTextInputField}
      label="Participants"
      type="number"
      max={calcMaxParticipants(courses[i]?.course, false, 1)}
      min={1}
      normalize={(v) => (!v ? v : Number(v ?? 0))}
      size="small"
      noBorderRadius
      onChange={handleChangeParticipants}
      disabled={(!courses[i]?.course ?? false) || !hasCreateAccess}
      disableClearable
    />
  );

  const additionalParticipantSlider = () => (
    <Field
      variant="outlined"
      placeholder="0"
      id="courseAdditionalParticipantsSlider"
      name={`${member}.additional_participants[0].qty`}
      component={MDSlider}
      label="Additional Participants"
      onChange={handleChangeAdditionalParticipants}
      disabled={additionalParticipantsUnavailable || (!courses[i]?.course ?? false) || !hasCreateAccess}
      max={calcMaxAdditionalParticipantsNew(courses[i]?.course, hasSecondaryInstructor)}
      min={0}
    />
  );

  const additionalParticipantsInput = () => (
    <Field
      variant="outlined"
      placeholder="Additional Participants"
      id={`courseAdditionalParticipants${i}${member}`}
      name={`${member}.additional_participants[0].qty`}
      component={MDTextInputField}
      label="Additional Participants"
      type="number"
      max={calcMaxAdditionalParticipantsNew(courses[i]?.course, hasSecondaryInstructor)}
      normalize={(v) => (!v ? v : Number(v ?? 0))}
      min={0}
      size="small"
      noBorderRadius
      onChange={handleChangeAdditionalParticipants}
      disabled={additionalParticipantsUnavailable || (!courses[i]?.course ?? false) || !hasCreateAccess}
      disableClearable
    />
  );

  const timeInfoContainer = () =>
    container({
      spacing: 1,
      children: [
        showStartEndTime &&
          container({
            spacing: 3,
            style: { alignItems: 'center', marginBottom: '3px' },
            children: [
              item({
                children: [
                  container({
                    spacing: 2,
                    style: { alignItems: 'center', paddingLeft: 6 },
                    children: [
                      editSTime && i > 0 ? item({ children: startTimeInput() }) : item({ children: startTimeLabel() }),
                      hasEndTime && item({ children: '-' }),
                      item({ children: endTimeLabel() }),
                    ],
                  }),
                ],
              }),
              isEditableTime && hasCreateAccess && item({ children: editBtn() }),
            ],
          }),
      ],
    });

  const pricingComponent = (forSkillcheck) => (
    <>
      {item({ xs: 12, children: coursePriceComponent(forSkillcheck) })}
      {(pricingError || (skillcheckPricingError && forSkillcheck)) && (
        <div style={{ display: 'grid', color: COLORS.CINTAS_RED }}>
          <snap>Pricing error found. Please, contact support and provide these details:</snap>
          {/* <snap>{`Material ID: ${ removeLeadingZeros(pricingError.material) } `}</snap>
                <snap>{`Sold - to: ${ removeLeadingZeros(pricingError.soldto) } `}</snap>
                <snap>{`Location: ${ pricingError.salesOffice } `}</snap>
                <snap>{`Sales Organization: ${ pricingError.salesOrg } `}</snap> */}
          <snap>{`Material ID: ${removeLeadingZeros((forSkillcheck ? skillcheckPricingError : pricingError)?.material)}, Sold - to: ${removeLeadingZeros((forSkillcheck ? skillcheckPricingError : pricingError)?.soldto)}, Location: ${(forSkillcheck ? skillcheckPricingError : pricingError)?.salesOffice}, Sales Organization: ${(forSkillcheck ? skillcheckPricingError : pricingError)?.salesOrg} `}</snap>
        </div>
      )}
      {divider()}
    </>
  );

  return (
    <Card
      elevation={3}
      sx={{
        minWidth: 580,
        marginBottom: i !== (courses ?? []).length - 1 ? 2 : 0,
        paddingTop: 2,
      }}
    >
      <CardContent>
        {item({
          key: `course${member} `,
          xs: 12,
          children: [
            container({
              xs: 12,
              children: [
                item({
                  xs: 10,
                  style: { paddingBottom: showStartEndTime ? 0 : 10 },
                  children: courseInput(),
                }),
                item({ xs: 2, children: showTashIcon && trashBtn() }),
              ],
            }),
            item({
              style: { paddingTop: 12 },
              xs: 12,
              children: timeInfoContainer(),
            }),
            pricingComponent(),
            item({
              xs: 12,
              children: [
                title('Participants'),
                container({
                  spacing: 4,
                  alignItems: 'center',
                  children: [
                    item({ xs: 12, sm: 7, children: participantSlider() }),
                    item({ xs: 12, sm: 4, children: participantsInput() }),
                  ],
                }),
              ],
            }),
            allowAddPart &&
              courses[i].course?.additionalParticipants?.id &&
              (divider(),
              item({
                xs: 12,
                children: [
                  title('Additional Participants', {
                    style: { color: additionalParticipantsUnavailable ? COLORS.CINTAS_GRAY : 'black' },
                  }),
                  additionalParticipantsUnavailable &&
                    title('Not available for selected customer', {
                      style: { color: COLORS.CINTAS_RED, fontStyle: 'italic' },
                    }),
                  container({
                    spacing: 4,
                    alignItems: 'center',
                    children: [
                      item({ xs: 12, sm: 7, children: additionalParticipantSlider() }),
                      item({ xs: 12, sm: 4, children: additionalParticipantsInput() }),
                    ],
                  }),
                ],
              })),
            allowAddPart && courses[i].additional_participants?.[0]?.qty > 0 && (
              <Typography variant="caption" color="textSecondary" component="p">
                {`${courses[i].additional_participants[0].qty} participant${courses[i].additional_participants[0].qty > 1 ? 's' : ''} will be billed per person`}
              </Typography>
            ),
          ],
        })}
        {hasSkillCheck &&
          item({
            key: `skillcheck${member} `,
            xs: 12,
            children: [divider(), item({ xs: 12, children: courseSkillCheck() })],
          })}
      </CardContent>
    </Card>
  );
};

export default connect((state) => ({
  tz: timezoneSelector(state),
}))(CourseSelect);

/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import _ from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import React from 'react';
import { Views } from 'react-big-calendar';
import { connect, useSelector } from 'react-redux';
import { calendarTypeSelector } from '../../../redux/selectors/calendar.selectors';
import { eventRevenueSelector } from '../../../redux/selectors/events.selectors';
import { showRevenueSelector } from '../../../redux/selectors/settings.selectors';
import { reportingTciById, userInfoSelector } from '../../../redux/selectors/user.selectors';
import { CALENDAR_TYPE, COLORS, EVENT_TYPE, MATERIAL_TYPE } from '../../../utils/consts';
import { normalizeInstructor } from '../../../utils/event.utils';
import { ccyFormat } from '../../../utils/helpers';
import BaseEventWrapper from './BaseEventWrapper';

const CustomEventWrapper = ({ event, materialsInfo, view, calendarType }) => {
  let courseName;
  let addr;
  let participants;
  let showWarning = false;
  let revenue = 0;
  let showRevenue = false;
  const showInstructorName = useSelector((state) => {
    const duration =
      moment.utc(event.event?.desc?.endTime).valueOf() - moment.utc(event.event?.desc?.startTime).valueOf();
    return (
      calendarTypeSelector(state) === CALENDAR_TYPE.location &&
      duration > (event.event?.desc?.eventType === EVENT_TYPE.TIME_BLOCK ? 1800000 : 3600000)
    ); // if event longer than 1 hour (for UX purpose)
  });
  const instructorName = useSelector((state) => {
    if (!showInstructorName) return undefined;
    let tci = reportingTciById(state, event.event?.desc?.instructorID);
    const selfTci = userInfoSelector(state);
    if (!tci && selfTci && selfTci.uid === event.event?.desc?.instructorID) {
      tci = selfTci;
    }
    if (!tci) return undefined;
    tci = normalizeInstructor(tci);
    return `${tci?.first_name?.[0] ?? ''}. ${tci?.last_name?.[0]}.`;
  });
  let combinedParticipantsCount = 0;
  let additionalParticipantsCount = 0;
  if (event.event.desc.eventType === EVENT_TYPE.ON_SITE) {
    const eventInfo = event.event.desc;
    participants = eventInfo.participants_count || 0;
    courseName = materialsInfo[MATERIAL_TYPE.COURSE][eventInfo.course] ?? {};
    addr = eventInfo.locationDisplay ?? '';
    showWarning = !eventInfo.signature;
    revenue = useSelector(
      (state) => eventRevenueSelector({ state, orderId: eventInfo.order, eventId: eventInfo.id }) ?? 0,
    );
    showRevenue = useSelector(
      (state) =>
        showRevenueSelector(state) && view === Views.DAY && revenue > 0 && calendarType !== CALENDAR_TYPE.location,
    );
    additionalParticipantsCount = eventInfo?.additional_participants?.[0]?.qty ?? 0;
    combinedParticipantsCount = (additionalParticipantsCount ?? 0) + (participants ?? 0);
  }

  return (
    <BaseEventWrapper
      title={event.title}
      showWarning={showWarning}
      footer={
        showRevenue || (showInstructorName && instructorName) ? (
          <div
            style={{
              bottom: 5,
              right: showRevenue ? 5 : undefined,
              left: showRevenue ? undefined : 5,
              // position: !event.isAllDay && 'absolute',
              paddingTop: 2,
              color: COLORS.CINTAS_MEDIUM_GRAY,
            }}
          >
            {showRevenue && ccyFormat(revenue)}
            {showInstructorName && instructorName}
          </div>
        ) : (
          <></>
        )
      }
    >
      {courseName && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span style={{ fontWeight: 400 }}>{courseName.title}</span>
          {participants && <span style={{ fontWeight: 400 }}>{combinedParticipantsCount}</span>}
        </div>
      )}
      {addr && (
        <div>
          <span style={{ fontWeight: 400 }}>{addr}</span>
        </div>
      )}
    </BaseEventWrapper>
  );
};

const mapStateToProps = (state) => ({
  orders: state.orders.upcoming,
  calendarType: calendarTypeSelector(state),
});

export default _.flow([connect(mapStateToProps)])(CustomEventWrapper);

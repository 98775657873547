/* eslint-disable max-len */
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import React from 'react';
import { connect, useSelector } from 'react-redux';

import { formValueSelector, getFormValues } from 'redux-form';
import { timezoneSelector } from '../../../../redux/selectors/utils.selectors';
import { BLOCK_REASON, COLORS, EVENT_TYPE, TIME_BLOCK_REASONS, TIME_FORMAT } from '../../../../utils/consts';
import { formatLocationTwoLines } from '../../../../utils/helpers';

const useStyles = makeStyles(() => ({
  root: {
    width: 250,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    flex: 1,
    flexWrap: 'wrap',
  },
  daytitle: {
    fontWeight: 'bold',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    color: '#000000',
    paddingBottom: '10px',
  },
  hourtime: {
    color: ({ props, isConflict }) => (props.checkConflict && isConflict ? COLORS.CINTAS_RED : '#454545'),
    display: 'flex',
    fontWeight: 'bold',
  },
  italic: {
    color: ({ props, isConflict }) => (props.checkConflict && isConflict ? COLORS.CINTAS_RED : '#454545'),
    display: 'flex',
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
  customer: {
    display: 'flex',
    fontWeight: 'bold',
    color: ({ props, isConflict }) => (props.checkConflict && isConflict ? COLORS.CINTAS_RED : '#000000'),
  },
  classinfo: {
    color: ({ props, isConflict }) => (props.checkConflict && isConflict ? COLORS.CINTAS_RED : '#000000'),
  },
}));

const UpcomingEvent = (props) => {
  const [isConflict, setIsConflict] = React.useState(false);
  const {
    event,
    course,
    selectedDay,
    checkConflict,
    userEndTime,
    userStartTime,
    userStartDate,
    userEndDate,
    selectedTimezone,
    allDay,
    showSecondaryLabel,
    isUpgrade,
  } = props;
  const classes = useStyles({ props, isConflict });
  const selectedTimezoneAbbrev = useSelector((state) => state.utils.tz.abbrev);
  const contactsData = useSelector((state) => state.contacts.data);
  const locationsData = useSelector((state) => state.locations.data);

  const { startTime, endTime, location, eventType, reason, blockType } = event;

  const userStartTimeInZone = moment.utc(allDay ? userStartDate : userStartTime);
  const userEndTimeInZone = moment.utc(allDay ? userEndDate : userEndTime);
  const eventStartTime = moment.utc(startTime);
  const eventEndTime = moment.utc(endTime);

  const isOverlap = (a, b, c, d) => {
    if (
      (c.isBefore(a) && a.isBefore(d)) ||
      (c.isBefore(b) && b.isBefore(d)) ||
      (c.isSameOrAfter(a) && d.isSameOrBefore(b))
    )
      return true;
    return false;
  };

  React.useEffect(() => {
    if (
      checkConflict &&
      !isUpgrade &&
      (isOverlap(userStartTimeInZone, userEndTimeInZone, eventStartTime, eventEndTime) || allDay || event.allDay)
    ) {
      setIsConflict(true);
      props.onConflict(true, event.id, event.title);
    } else {
      setIsConflict(false);
      props.onConflict(false, event.id, event.title);
    }
  }, [...(checkConflict ? [userEndTime, userStartTime] : ['']), event.allDay, allDay, isUpgrade]);

  // ON-SITE
  const eventContact = _.find(contactsData, {
    SAPContactID: event.contactID,
    SAPCustomerID: event.customerID,
  });

  // OPEN-ENROLLMENT
  const openEnrollmentLocation = location && _.find(locationsData, { id: location });
  const eventLoc =
    eventContact && eventType === 'ON-SITE'
      ? eventContact.location
      : openEnrollmentLocation && openEnrollmentLocation.address;

  const duration = `${eventStartTime.tz(selectedTimezone).format(TIME_FORMAT)} - ${eventEndTime.tz(selectedTimezone).format(TIME_FORMAT)} (${selectedTimezoneAbbrev})`;

  const upgradeStyle = {
    textDecoration: isUpgrade ? 'line-through' : 'none',
  };

  return (
    <CardContent style={{ padding: '16px 0' }}>
      <Typography className={classes.hourtime} variant="body2" color="textSecondary" component="p" style={upgradeStyle}>
        {event.allDay ? 'ALL DAY' : duration}
      </Typography>
      {showSecondaryLabel && (
        <Typography className={classes.italic} variant="body2" color="textSecondary" component="p">
          Secondary TCI
        </Typography>
      )}
      {eventType === EVENT_TYPE.ON_SITE && (
        <>
          {selectedDay && (
            <Typography className={classes.customer} variant="body2" color="textSecondary" component="p">
              {`${eventType} - ${event?.title}`}
            </Typography>
          )}
          {!selectedDay && (
            <>
              <Typography
                className={classes.classinfo}
                variant="body2"
                color="textSecondary"
                component="p"
                gutterBottom
              >
                {course.title}
              </Typography>
              {(eventContact && (
                <>
                  <Typography className={classes.classinfo} variant="body2" color="textSecondary" component="p">
                    {`${_.capitalize(eventContact.FirstName)} ${_.capitalize(eventContact.LastName)}`}
                  </Typography>
                  <Typography className={classes.classinfo} variant="body2" color="textSecondary" component="p">
                    {formatLocationTwoLines(eventContact.Addresses[0])[0]}
                  </Typography>
                  <Typography className={classes.classinfo} variant="body2" color="textSecondary" component="p">
                    {formatLocationTwoLines(eventContact.Addresses[0])[1]}
                  </Typography>
                </>
              )) || (
                <Typography className={classes.customer} variant="body2" color="textSecondary" component="p">
                  Onsite Event
                </Typography>
              )}
            </>
          )}
        </>
      )}
      {eventType === EVENT_TYPE.TIME_BLOCK && reason && (
        <Typography
          className={classes.customer}
          variant="body2"
          color="textSecondary"
          component="p"
          style={upgradeStyle}
        >
          {`${TIME_BLOCK_REASONS[reason] ?? TIME_BLOCK_REASONS[BLOCK_REASON.OTHER]} - ${blockType}`}
        </Typography>
      )}
      {eventLoc && !selectedDay && (
        <Typography variant="caption" color="textSecondary" component="p">
          {`${eventLoc.address1},`}
          {eventLoc.address2 ? ` ${eventLoc.address2}, ` : ' '}
          {`${eventLoc.city}, ${eventLoc.state} ${eventLoc.zip}`}
        </Typography>
      )}
    </CardContent>
  );
};

// export default UpcomingEvent;
const selector = formValueSelector('AddEventDialog');

export default _.flow([
  connect((state) => ({
    userStartTime: selector(state, 'startTime'),
    userEndTime: selector(state, 'endTime'),
    userStartDate: selector(state, 'date'),
    userEndDate: selector(state, 'endDate'),
    selectedTimezone: timezoneSelector(state),
    allDay: (getFormValues('AddEventDialog')(state) ?? {}).allDay,
  })),
])(UpcomingEvent);
